import React, {ChangeEvent, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {ReactComponent as Image} from './assets/img.svg';
import {Button, Card, Container, FormControl, Grid, Link, Stack, styled, Switch, Typography} from '@mui/material';
import {ERROR_MESSAGE, ROUTES, setTokens} from 'utils';
import {useAuthControllerLoginUser} from 'api/authentication';
import {useQsParams} from 'hooks/useQsParams';
import {Form, FormTextField} from 'components/form';
import {useUserControllerSignUserAgreement} from 'api/user';

const schema = yup.object({
    username: yup.string().required(ERROR_MESSAGE.REQUIRED),
    password: yup.string().required(ERROR_MESSAGE.REQUIRED).min(8, 'Минимальное количество символов - 8')
});

type FormData = {
    username: string;
    password: string;
    userAgreement: boolean;
};

export const SignIn = () => {
    const form = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            username: '',
            password: '',
            userAgreement: false
        }
    });
    const {setError, control} = form;
    const login = useAuthControllerLoginUser();
    const signAgreement = useUserControllerSignUserAgreement();
    const [params] = useQsParams();
    const [showAgreement, setShowAgreement] = useState(false);
    const onSubmit = (data: FormData) => {
        if (data.userAgreement) {
            signAgreement.mutateAsync().then(() => handleLogin(data));
        } else {
            handleLogin(data);
        }
    };
    const handleLogin = (data: FormData) => {
        login
            .mutateAsync({data})
            .then((res) => {
                setTokens(res);
                if (res.isUserAgreement) {
                    window.location.href = params.redirectUrl || ROUTES.BASE;
                } else {
                    setShowAgreement(true);
                }
            })
            .catch((err) => {
                const setErr = (message: string) => {
                    setError('username', {type: 'invalid', message: message});
                    setError('password', {type: 'invalid', message: message});
                };
                switch (err.message) {
                    case 'INVALID_CREDENTIALS':
                        setErr(ERROR_MESSAGE.INVALID_CREDENTIALS);
                        break;
                    case 'USER_BLOCKED':
                        setErr(ERROR_MESSAGE.USER_BLOCKED);
                        break;
                    default:
                        break;
                }
            });
    };

    return (
        <Container sx={{pt: 7.5}}>
            <Grid container alignItems='flex-end' columnSpacing={14}>
                <Grid item xs={12} lg={6.5} mb={{xs: 5, lg: 0}}>
                    <Stack direction='row' spacing={1}>
                        <Typography variant='h1' sx={{color: 'secondary.dark', lineHeight: 1}}>
                            Мой
                        </Typography>
                        <Typography variant='h1' sx={{color: 'secondary.main', lineHeight: 1}}>
                            профиль
                        </Typography>
                    </Stack>
                    <Typography variant='h1' sx={{color: 'primary.main'}} mb={2}>
                        вход
                    </Typography>
                    <Form form={form} onSubmit={onSubmit}>
                        <Card
                            sx={{
                                padding: '25px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 3
                            }}>
                            <FormTextField name='username' label='Почта' />
                            <FormTextField name='password' label='Пароль' type='password' />
                            {showAgreement && (
                                <FormControl sx={{mt: 2, alignItems: 'flex-start'}}>
                                    <Link
                                        href={ROUTES.USER_AGREEMENT}
                                        variant='h2'
                                        target='_blank'
                                        sx={{
                                            mb: 1,
                                            textTransform: 'uppercase',
                                            '&:hover': {
                                                textDecoration: 'underline !important'
                                            }
                                        }}>
                                        Пользовательское соглашение
                                    </Link>
                                    <Controller
                                        control={control}
                                        name='userAgreement'
                                        render={({field}) => (
                                            <StyledSwitch
                                                checked={field.value}
                                                onChange={(event: ChangeEvent<HTMLInputElement>) => field.onChange(event.target.checked)}
                                                disableRipple
                                                disableFocusRipple
                                            />
                                        )}
                                    />
                                </FormControl>
                            )}

                            <Button variant='contained' type='submit' sx={{mt: 3}}>
                                Войти
                            </Button>
                        </Card>
                    </Form>
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <Image />
                </Grid>
            </Grid>
            <Grid sx={{mt: 2}}>
                <Link
                    href={`mailto:${process.env.REACT_APP_FEEDBACK_EMAIL}`}
                    sx={{color: (theme) => theme.palette.text.primary, textDecoration: 'none', fontWeight: 'bold'}}>
                    <Typography variant='body2'>Обратная связь</Typography>
                </Link>
            </Grid>
        </Container>
    );
};

const StyledSwitch = styled(Switch)({
    padding: 4,
    margin: -4,
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#fff',

        '&:hover': {
            backgroundColor: 'inherit'
        },
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: '#e04e39'
        },
        '& > .MuiSwitch-thumb': {
            backgroundColor: '#fff'
        }
    },
    '& .MuiSwitch-switchBase:hover': {
        backgroundColor: 'inherit'
    },
    '& .MuiSwitch-track': {
        border: '3px solid #666666',
        backgroundColor: '#fff',
        borderRadius: 20,
        opacity: 1,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 20,
            height: 20
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        backgroundColor: '#666666',
        width: 18,
        height: 18,
        margin: 1
    }
});
