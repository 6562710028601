/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions, QueryFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {PaginatedTerritorialUnit, TerritorialUnitControllerFindAllParams} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const territorialUnitControllerFindAll = (params?: TerritorialUnitControllerFindAllParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedTerritorialUnit>({url: `/public/territorial-unit`, method: 'get', params, signal});
};

export const getTerritorialUnitControllerFindAllQueryKey = (params?: TerritorialUnitControllerFindAllParams) => [
    `/public/territorial-unit`,
    ...(params ? [params] : [])
];

export type TerritorialUnitControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof territorialUnitControllerFindAll>>>;
export type TerritorialUnitControllerFindAllQueryError = unknown;

export const useTerritorialUnitControllerFindAll = <TData = Awaited<ReturnType<typeof territorialUnitControllerFindAll>>, TError = unknown>(
    params?: TerritorialUnitControllerFindAllParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof territorialUnitControllerFindAll>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getTerritorialUnitControllerFindAllQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof territorialUnitControllerFindAll>>> = ({signal}) =>
        territorialUnitControllerFindAll(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof territorialUnitControllerFindAll>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
