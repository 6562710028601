/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useMutation} from '@tanstack/react-query';
import type {UseMutationOptions, MutationFunction} from '@tanstack/react-query';
import type {Visit, CreateVisitDto} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const visitControllerCreate = (createVisitDto: CreateVisitDto) => {
    return axiosInstance<Visit>({url: `/public/visit`, method: 'post', headers: {'Content-Type': 'application/json'}, data: createVisitDto});
};

export type VisitControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof visitControllerCreate>>>;
export type VisitControllerCreateMutationBody = CreateVisitDto;
export type VisitControllerCreateMutationError = unknown;

export const useVisitControllerCreate = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof visitControllerCreate>>, TError, {data: CreateVisitDto}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof visitControllerCreate>>, {data: CreateVisitDto}> = (props) => {
        const {data} = props ?? {};

        return visitControllerCreate(data);
    };

    return useMutation<Awaited<ReturnType<typeof visitControllerCreate>>, TError, {data: CreateVisitDto}, TContext>(mutationFn, mutationOptions);
};
