/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useMutation} from '@tanstack/react-query';
import type {UseMutationOptions, MutationFunction} from '@tanstack/react-query';
import type {UserFavoriteStory, CreateStoryFavoriteDto} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const storyFavoriteControllerCreate = (createStoryFavoriteDto: CreateStoryFavoriteDto) => {
    return axiosInstance<UserFavoriteStory>({
        url: `/public/story-favorite`,
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        data: createStoryFavoriteDto
    });
};

export type StoryFavoriteControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof storyFavoriteControllerCreate>>>;
export type StoryFavoriteControllerCreateMutationBody = CreateStoryFavoriteDto;
export type StoryFavoriteControllerCreateMutationError = unknown;

export const useStoryFavoriteControllerCreate = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof storyFavoriteControllerCreate>>, TError, {data: CreateStoryFavoriteDto}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof storyFavoriteControllerCreate>>, {data: CreateStoryFavoriteDto}> = (props) => {
        const {data} = props ?? {};

        return storyFavoriteControllerCreate(data);
    };

    return useMutation<Awaited<ReturnType<typeof storyFavoriteControllerCreate>>, TError, {data: CreateStoryFavoriteDto}, TContext>(
        mutationFn,
        mutationOptions
    );
};
export const storyFavoriteControllerRemoveOne = (id: string) => {
    return axiosInstance<boolean>({url: `/public/story-favorite/${id}`, method: 'delete'});
};

export type StoryFavoriteControllerRemoveOneMutationResult = NonNullable<Awaited<ReturnType<typeof storyFavoriteControllerRemoveOne>>>;

export type StoryFavoriteControllerRemoveOneMutationError = unknown;

export const useStoryFavoriteControllerRemoveOne = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof storyFavoriteControllerRemoveOne>>, TError, {id: string}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof storyFavoriteControllerRemoveOne>>, {id: string}> = (props) => {
        const {id} = props ?? {};

        return storyFavoriteControllerRemoveOne(id);
    };

    return useMutation<Awaited<ReturnType<typeof storyFavoriteControllerRemoveOne>>, TError, {id: string}, TContext>(mutationFn, mutationOptions);
};
