import cookies from 'js-cookie';

const accessTokenName = 'mdd-access-token';
const refreshTokenName = 'mdd-refresh-token';
const domain = process.env.REACT_APP_NODE_ENV === 'production' ? '.mos.ru' : window.location.hostname;
export const getTokens = () => ({
    accessToken: cookies.get(accessTokenName),
    refreshToken: cookies.get(refreshTokenName)
});
export const setTokens = (data: any) => {
    cookies.set(accessTokenName, data?.accessToken, {domain});
    cookies.set(refreshTokenName, data?.refreshToken, {domain});
};
export const removeTokens = () => {
    cookies.remove(accessTokenName, {domain});
    cookies.remove(refreshTokenName, {domain});
};
