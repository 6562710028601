import {useMemo} from 'react';
import {NavigateOptions, useSearchParams} from 'react-router-dom';
import qs from 'qs';
import {cleanData} from 'utils';

export const useQsParams = (): [
    {[key: string]: any},
    (value: {[key: string]: any}, options?: NavigateOptions) => void
] => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useMemo(() => qs.parse(searchParams.toString(), {arrayLimit: 100}), [searchParams]);
    const setParams = (value: {[key: string]: any}, options?: NavigateOptions) => {
        setSearchParams(qs.stringify(cleanData(value)), options);
    };
    return [params, setParams];
};
