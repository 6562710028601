import React, {useEffect} from 'react';
import {Button, Grid} from '@mui/material';
import {useForm} from 'react-hook-form';
import {useQsParams} from 'hooks';
import {Form, FormDatePicker, FormTextField} from 'components/form';
import {HeaderSearchAutocomplete} from 'components/shared/HeaderSearch/HeaderSearchAutocomplete';
import {departmentControllerFindAllUnique, getDepartmentControllerFindAllUniqueQueryKey} from 'api/department';

export const StoriesSearchInputs = () => {
    const form = useForm();
    const {reset} = form;
    const [params, setParams] = useQsParams();
    const {search, date, department} = params;

    const onSubmit = (data: any) => {
        const newData = data;
        // Это нужно чтобы other не отправлялся в search params, но при этом при этом данные из other нам нужны в рейтингах
        Object.keys(newData).forEach(function (key) {
            if (newData[key] && newData[key]?.other) {
                delete newData[key].other;
            }
        });
        setParams({...params, ...newData});
    };

    useEffect(() => {
        reset({
            search,
            department,
            date: Array.isArray(date) ? date?.map((el: any) => (String(new Date(el)) !== 'Invalid Date' ? new Date(el) : '')) : []
        });
    }, [params]);

    return (
        <Form form={form} onSubmit={onSubmit}>
            <Grid container spacing={2.5}>
                <Grid item xs={12} md={6} lg={4}>
                    <FormTextField color='secondary' size='small' name='search' isFilter placeholder='Поиск по имени, хештегу или названию' />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormDatePicker size='small' name='date' placeholder='Выберите даты' color='secondary' />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <HeaderSearchAutocomplete
                        name='department'
                        optionText='abbreviation'
                        queryProps={{
                            query: departmentControllerFindAllUnique,
                            queryKey: getDepartmentControllerFindAllUniqueQueryKey
                        }}
                        placeholder='Выберите подразделение'
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={1}>
                    <Button
                        fullWidth
                        variant='contained'
                        color='inherit'
                        type='submit'
                        sx={{
                            height: 45,
                            bgcolor: 'white',
                            color: 'text.primaryConst',
                            '&:hover': {bgcolor: 'white', color: 'text.primaryConst'}
                        }}>
                        Найти
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
};
