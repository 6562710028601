import React, {useMemo} from 'react';
import {Button, Tab, ToggleButton, ToggleButtonGroup} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {useNavigate, useParams} from 'react-router-dom';
import {Stories} from 'components/shared';
import {useQsParams} from 'hooks';
import {CATEGORIES_TAB} from 'utils';
import {useTagControllerFindAll} from 'api/tag';

const TABS = [
    {
        title: 'Последние',
        value: CATEGORIES_TAB.LATEST
    },
    {
        title: 'Лучшие',
        value: CATEGORIES_TAB.BEST
    },
    {
        title: 'Прочитанные',
        value: CATEGORIES_TAB.VIEWED
    },
    {
        title: 'Непрочитанные',
        value: CATEGORIES_TAB.NOT_VIEWED
    },
    {
        title: 'Все',
        value: CATEGORIES_TAB.ALL
    }
];
export const Categories = () => {
    const {category} = useParams();
    const [params, setParams] = useQsParams();
    const {tagIds, tags} = params;
    const navigate = useNavigate();
    const {data} = useTagControllerFindAll({'pagination[skip]': 0, 'pagination[take]': 0});

    const handleChangeTab = (event: React.SyntheticEvent, value: any) => {
        navigate('/' + value + location.search);
    };
    const handleChangeTag = (event: React.MouseEvent<HTMLElement>, value: any) => {
        setParams({...params, tagIds: value});
    };
    const tagIdsNumeric = useMemo(() => tagIds?.map((i: string) => +i), [tagIds]);

    return (
        <TabContext value={category || CATEGORIES_TAB.ALL}>
            <TabList sx={{py: 6}} onChange={handleChangeTab}>
                {TABS.map((item, index) => (
                    <Tab key={index} label={item.title} value={item.value} disableRipple />
                ))}
                <Button
                    sx={{
                        color: tags ? 'text.primary' : 'text.secondary',
                        typography: 'h2',
                        '&:hover': {bgcolor: 'transparent', color: 'text.primary'}
                    }}
                    disableRipple
                    onClick={() => setParams({...params, tags: !tags || undefined, tagIds: undefined})}>
                    #Теги
                </Button>
            </TabList>
            {tags && (
                <ToggleButtonGroup onChange={handleChangeTag} value={tagIdsNumeric} sx={{flexWrap: 'wrap', columnGap: 1, rowGap: 2, pb: 5}}>
                    {data?.data?.map((el, index) => (
                        <ToggleButton key={index} value={el.id} color='primary'>
                            {el.name}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            )}

            {TABS.map((item, index) => (
                <TabPanel key={index} value={item.value} sx={{p: 0}}>
                    <Stories />
                </TabPanel>
            ))}
        </TabContext>
    );
};
