import {FieldValues, FormProvider, UseFormReturn} from 'react-hook-form';
import {ReactNode} from 'react';

export type FormProps<T extends FieldValues> = {
    form: UseFormReturn<T>;
    children?: ReactNode;
    onSubmit?: (data: T) => void;
};

export const Form = <T extends FieldValues>(props: FormProps<T>) => {
    const {form, children, onSubmit, ...rest} = props;

    const {handleSubmit} = form;
    return (
        <FormProvider {...form}>
            <form
                noValidate
                onSubmit={(e) => {
                    onSubmit && handleSubmit(onSubmit)();
                    e.preventDefault();
                }}
                {...rest}>
                {children}
            </form>
        </FormProvider>
    );
};
