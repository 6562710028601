import {Box, styled} from '@mui/material';

export const Title = styled(Box)(({theme}) => ({
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '12pt',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
}));

export const Text = styled(Box)({
    textAlign: 'justify',

    fontSize: '12pt',
    ul: {
        marginBottom: 0,
        marginTop: 0
    }
});

export const TextToRight = styled('div')({
    marginLeft: 'auto',
    textAlign: 'right'
});

export const TextNumber = styled('div')({
    textAlign: 'left',
    fontSize: '12pt',
    minWidth: '75px'
});

export const TextLine = styled('div')({
    display: 'flex'
});

export const Link = styled('a')({
    textDecoration: 'underline'
});

export const Space = styled('div')({
    display: 'flex',
    height: '150px'
});
