/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useMutation} from '@tanstack/react-query';
import type {UseMutationOptions, MutationFunction} from '@tanstack/react-query';
import type {StoryView, CreateStoryViewDto} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const storyViewControllerCreate = (createStoryViewDto: CreateStoryViewDto) => {
    return axiosInstance<StoryView>({
        url: `/public/story-view`,
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        data: createStoryViewDto
    });
};

export type StoryViewControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof storyViewControllerCreate>>>;
export type StoryViewControllerCreateMutationBody = CreateStoryViewDto;
export type StoryViewControllerCreateMutationError = unknown;

export const useStoryViewControllerCreate = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof storyViewControllerCreate>>, TError, {data: CreateStoryViewDto}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof storyViewControllerCreate>>, {data: CreateStoryViewDto}> = (props) => {
        const {data} = props ?? {};

        return storyViewControllerCreate(data);
    };

    return useMutation<Awaited<ReturnType<typeof storyViewControllerCreate>>, TError, {data: CreateStoryViewDto}, TContext>(
        mutationFn,
        mutationOptions
    );
};
