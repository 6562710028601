import React, {useCallback} from 'react';
import {CircularProgress, Skeleton, Table, TableBody, TableCell, TableFooter, TableHead, TableRow} from '@mui/material';
import {TableVirtuoso} from 'react-virtuoso';

export const TableScroll = ({columns, loading, data, load, height, minWidth}: any) => {
    const loadMore = useCallback(() => {
        load();
    }, [load]);
    const fixedHeaderContent = () => {
        return (
            <TableRow>
                <TableCell
                    variant='head'
                    style={{width: 100}}
                    sx={{
                        backgroundColor: 'background.paper'
                    }}
                />
                {columns.map((column: any) => (
                    <TableCell
                        key={column.dataKey}
                        variant='head'
                        align={column.numeric || false ? 'right' : 'left'}
                        style={{width: 'auto'}}
                        sx={{
                            backgroundColor: 'background.paper'
                        }}>
                        {column.label}
                    </TableCell>
                ))}
            </TableRow>
        );
    };
    const rowContent = (index: number, row: any) => {
        return (
            <>
                <TableCell
                    sx={{backgroundColor: (theme) => (index % 2 === 0 ? 'inherit' : theme.palette.background.stripe)}}>
                    {index + 1}
                </TableCell>
                {columns.map((column: any) => (
                    <TableCell
                        key={column.dataKey}
                        align={column.numeric ? 'right' : 'left'}
                        sx={{
                            overflow: 'auto',
                            backgroundColor: (theme) => (index % 2 === 0 ? 'inherit' : theme.palette.background.stripe)
                        }}>
                        {data && data?.length > 0 ? (
                            column?.value(row)
                        ) : (
                            <Skeleton sx={{ml: column.numeric ? 'auto' : '0', width: {xs: 'auto', md: 150}}} />
                        )}
                    </TableCell>
                ))}
            </>
        );
    };
    const VirtuosoTableComponents = {
        Table: (props: any) => (
            <>
                <Table
                    {...props}
                    sx={{
                        borderCollapse: 'separate',
                        tableLayout: 'fixed',
                        '& th': {position: 'sticky', top: 0}
                    }}
                />
                {loading && (
                    <TableFooter sx={{display: 'flex', justifyContent: 'center', p: 2}}>
                        <CircularProgress />
                    </TableFooter>
                )}
            </>
        ),
        TableHead,
        TableRow,
        TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />)
    };

    const SkeletonArray = [...Array(15).keys()].map(() => ({id: 0, name: '', count: 0}));
    return (
        <TableVirtuoso
            style={{height: height || 572, minWidth: minWidth}}
            endReached={loadMore}
            overscan={15}
            data={data && data?.length > 0 ? data : SkeletonArray}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
        />
    );
};
