import {ROUTES} from './utils';
import {MainLayout} from './components/layouts';
import {RouteObject} from 'react-router';
import {CreateStory, Favorite, Home, Liked, Profile, Rating, SignIn, UserAgreement} from 'pages';
import {AccessControl, Visit} from 'components';

export const routes: RouteObject[] = [
    {
        element: (
            <AccessControl>
                <Visit>
                    <MainLayout />
                </Visit>
            </AccessControl>
        ),
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: ':category',
                element: <Home />
            },

            {
                path: ROUTES.CREATE,
                element: <CreateStory />
            },
            {
                path: ROUTES.DRAFT + '/:storyId',
                element: <CreateStory />
            },
            {
                path: ROUTES.USER_AGREEMENT,
                element: <UserAgreement />
            },
            {
                path: ROUTES.FAVORITE,
                element: <Favorite />
            },
            {
                path: ROUTES.LIKED,
                element: <Liked />
            },
            {
                path: ROUTES.RATING,
                element: <Rating />
            },
            {
                path: ROUTES.PROFILE,
                element: <Profile />
            }
        ]
    },
    {
        path: ROUTES.SIGN_IN,
        element: <SignIn />
    }
];
