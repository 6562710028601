/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useMutation} from '@tanstack/react-query';
import type {UseMutationOptions, MutationFunction} from '@tanstack/react-query';
import type {Like, CreateOrRemoveLikeDto} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const likeControllerCreate = (createOrRemoveLikeDto: CreateOrRemoveLikeDto) => {
    return axiosInstance<Like>({url: `/public/like`, method: 'post', headers: {'Content-Type': 'application/json'}, data: createOrRemoveLikeDto});
};

export type LikeControllerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof likeControllerCreate>>>;
export type LikeControllerCreateMutationBody = CreateOrRemoveLikeDto;
export type LikeControllerCreateMutationError = unknown;

export const useLikeControllerCreate = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof likeControllerCreate>>, TError, {data: CreateOrRemoveLikeDto}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof likeControllerCreate>>, {data: CreateOrRemoveLikeDto}> = (props) => {
        const {data} = props ?? {};

        return likeControllerCreate(data);
    };

    return useMutation<Awaited<ReturnType<typeof likeControllerCreate>>, TError, {data: CreateOrRemoveLikeDto}, TContext>(
        mutationFn,
        mutationOptions
    );
};
export const likeControllerRemove = (createOrRemoveLikeDto: CreateOrRemoveLikeDto) => {
    return axiosInstance<boolean>({
        url: `/public/like`,
        method: 'delete',
        headers: {'Content-Type': 'application/json'},
        data: createOrRemoveLikeDto
    });
};

export type LikeControllerRemoveMutationResult = NonNullable<Awaited<ReturnType<typeof likeControllerRemove>>>;
export type LikeControllerRemoveMutationBody = CreateOrRemoveLikeDto;
export type LikeControllerRemoveMutationError = unknown;

export const useLikeControllerRemove = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof likeControllerRemove>>, TError, {data: CreateOrRemoveLikeDto}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof likeControllerRemove>>, {data: CreateOrRemoveLikeDto}> = (props) => {
        const {data} = props ?? {};

        return likeControllerRemove(data);
    };

    return useMutation<Awaited<ReturnType<typeof likeControllerRemove>>, TError, {data: CreateOrRemoveLikeDto}, TContext>(
        mutationFn,
        mutationOptions
    );
};
