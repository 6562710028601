import React, {useEffect} from 'react';
import {Button, Grid} from '@mui/material';
import {useForm} from 'react-hook-form';
import {useQsParams} from 'hooks';
import {Form, FormDatePicker} from 'components/form';
import {HeaderSearchAutocomplete} from 'components/shared/HeaderSearch/HeaderSearchAutocomplete';
import {departmentControllerFindAllUnique, getDepartmentControllerFindAllUniqueQueryKey} from 'api/department';
import {divisionControllerFindAll, getDivisionControllerFindAllQueryKey} from 'api/division';
import {districtControllerFindAll, getDistrictControllerFindAllQueryKey} from 'api/district';
import {getTerritorialUnitControllerFindAllQueryKey, territorialUnitControllerFindAll} from 'api/territorial-unit';

export const RatingSearchInputs = () => {
    const form = useForm();
    const {reset, watch, setValue} = form;
    const [params, setParams] = useQsParams();
    const {date, department, division, district, territorialUnit} = params;
    const onSubmit = (data: any) => {
        const newData = data;
        Object.keys(newData).forEach(function (key) {
            if (newData[key] && newData[key]?.other) {
                delete newData[key].other;
            }
        });
        setParams({...params, ...newData});
    };
    useEffect(() => {
        reset({
            department,
            division,
            district,
            territorialUnit,
            date: Array.isArray(date) ? date?.map((el: any) => (String(new Date(el)) !== 'Invalid Date' ? new Date(el) : '')) : []
        });
    }, [params]);
    const watchDepartment = watch('department');
    const watchDivision = watch('division');
    const watchDistrict = watch('district');
    const watchTerritorialUnit = watch('territorialUnit');
    useEffect(() => {
        if (watchDepartment) {
            if (watchDepartment?.other?.territorialUnit && !watchTerritorialUnit) {
                setValue('territorialUnit', {
                    name: watchDepartment?.other?.territorialUnit?.name,
                    id: watchDepartment?.other?.territorialUnit?.id,
                    divisionId: watchDepartment?.other?.territorialUnit?.division?.id,
                    divisionName: watchDepartment?.other?.territorialUnit?.division?.name
                });
            }
            if (watchDepartment?.other?.districtId && !watchDistrict) {
                setValue('district', {
                    name: watchDepartment?.other?.district?.name,
                    id: watchDepartment?.other?.district?.id
                });
            }
        }
    }, [watchDepartment]);

    useEffect(() => {
        if (watchDistrict && String(watchDistrict?.id) !== String(watchDepartment?.districtId)) {
            setValue('department', undefined);
        }
    }, [watchDistrict]);

    useEffect(() => {
        if (watchDivision && watchTerritorialUnit && String(watchDivision?.id) !== String(watchTerritorialUnit?.divisionId)) {
            setValue('territorialUnit', undefined);
            setValue('department', undefined);
        }
    }, [watchDivision]);

    useEffect(() => {
        if (watchTerritorialUnit) {
            if (String(watchTerritorialUnit?.id) !== String(watchDepartment?.territorialUnitId)) {
                setValue('department', undefined);
            }
            if (!watchDivision) {
                setValue('division', {
                    name: watchTerritorialUnit?.other?.division?.name || watchTerritorialUnit?.divisionName,
                    id: watchTerritorialUnit?.other?.division?.id || watchTerritorialUnit?.divisionId
                });
            }
        }
    }, [watchTerritorialUnit]);

    return (
        <Form form={form} onSubmit={onSubmit}>
            <Grid container spacing={2.5}>
                <Grid item xs={12} sm={3} md={4} lg={2.2}>
                    <FormDatePicker size='small' name='date' placeholder='Выберите даты' color='secondary' />
                </Grid>
                <Grid item xs={12} sm={3} md={4} lg={2.2}>
                    <HeaderSearchAutocomplete
                        name='district'
                        optionText='name'
                        queryProps={{
                            query: districtControllerFindAll,
                            queryKey: getDistrictControllerFindAllQueryKey
                        }}
                        placeholder='Выберите округ'
                    />
                </Grid>
                <Grid item xs={12} sm={3} md={4} lg={2.2}>
                    <HeaderSearchAutocomplete
                        name='division'
                        optionText='name'
                        queryProps={{
                            query: divisionControllerFindAll,
                            queryKey: getDivisionControllerFindAllQueryKey
                        }}
                        placeholder='Выберите дивизион'
                    />
                </Grid>
                <Grid item xs={12} sm={3} md={4} lg={2.2}>
                    <HeaderSearchAutocomplete
                        name='territorialUnit'
                        optionText='name'
                        queryProps={{
                            query: territorialUnitControllerFindAll,
                            queryKey: getTerritorialUnitControllerFindAllQueryKey
                        }}
                        filter={{'filter[divisionId]': watchDivision?.id}}
                        placeholder='Выберите ТО'
                        additionalValues={['divisionId']}
                    />
                </Grid>
                <Grid item xs={12} sm={3} md={4} lg={2.2}>
                    <HeaderSearchAutocomplete
                        name='department'
                        optionText='abbreviation'
                        queryProps={{
                            query: departmentControllerFindAllUnique,
                            queryKey: getDepartmentControllerFindAllUniqueQueryKey
                        }}
                        filter={{
                            'filter[districtId]': watchDistrict?.id,
                            'filter[divisionId]': watchDivision?.id,
                            'filter[territorialUnitId]': watchTerritorialUnit?.id
                        }}
                        placeholder='Выберите подразделение'
                        additionalValues={['territorialUnitId', 'districtId']}
                    />
                </Grid>
                <Grid item xs={12} sm={3} md={4} lg={1}>
                    <Button
                        fullWidth
                        variant='contained'
                        color='inherit'
                        type='submit'
                        sx={{
                            height: 45,
                            bgcolor: 'white',
                            color: 'text.primaryConst',
                            '&:hover': {bgcolor: 'white', color: 'text.primaryConst'}
                        }}>
                        Найти
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
};
