import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {trimText} from 'utils';
import moment from 'moment';
import {PublicStoryDto} from 'api/mDDPublic.schemas';
import {useQsParams} from 'hooks';
import {FavoriteIcon, StyledCommentIcon, StyledHeartIcon} from './components';
import {PinnedIcon} from 'assets/icons';

export const StoryCard = ({data}: {data: PublicStoryDto}) => {
    const [params, setParams] = useQsParams();

    return (
        <Box
            onClick={() => setParams({...params, storyId: data.id})}
            sx={{
                height: '100%',
                cursor: 'pointer',
                background: (theme) => theme.palette.background.card,
                borderRadius: '10px',
                padding: 2.5,
                border: (theme) =>
                    '3px solid' + (data?.isFavorite ? theme.palette.secondary.main : !data?.isViewed ? theme.palette.primary.main : '#f4f4f4')
            }}>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1}}>
                <Typography variant='body2' sx={{fontWeight: 'bold', maxWidth: '100%'}}>
                    {data?.authors?.[0]?.user?.lastName} {data?.authors?.[0]?.user?.firstName}
                </Typography>
                <Stack direction='row' spacing={1} alignItems='center'>
                    {data?.isPinned && <PinnedIcon />}
                    <FavoriteIcon id={data?.id!} isFavorite={!!data?.isFavorite} />
                </Stack>
            </Box>
            <Box sx={{pt: '100%', overflow: 'hidden', position: 'relative', mb: 2, borderRadius: '10px'}}>
                <Box
                    component='img'
                    src={data?.file?.url}
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        objectFit: 'cover'
                    }}
                />
            </Box>
            <Typography variant='body2' fontWeight='bold' sx={{maxWidth: '100%', mb: 2, wordBreak: 'break-word'}}>
                {trimText(data?.title, 32)}
            </Typography>
            <Box
                component='div'
                sx={{
                    maxWidth: '100%',
                    mb: 5,
                    wordBreak: 'break-word',
                    minHeight: '60px',
                    '& .mention': {
                        borderRadius: '2px',
                        backgroundColor: '#c6ddf4',
                        cursor: 'pointer',
                        color: '#2c2a29'
                    }
                }}
                dangerouslySetInnerHTML={{__html: trimText(data?.description, 106)}}
            />

            <Box sx={{display: 'flex', justifyContent: 'space-between', fontStyle: 'italic', mb: 2, mt: 'auto'}}>
                <Typography sx={{color: '#767575'}}>{moment(data?.createdAt).format('DD.MM.YYYY')}</Typography>
                <Typography
                    sx={{
                        cursor: 'pointer'
                    }}>
                    Читать полностью
                </Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', fontStyle: 'italic'}}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                    <StyledHeartIcon isLiked={data?.isLiked} />
                    <Typography sx={{mr: 1, mb: 0.5}}>{data?.likesCount}</Typography>
                    <StyledCommentIcon />
                    <Typography sx={{mb: 0.5}}>{data?.commentsCount}</Typography>
                </Box>
                <Typography>{data?.isViewed ? 'Прочитано' : 'Не прочитано'}</Typography>
            </Box>
        </Box>
    );
};
