/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions, QueryFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {Number, StatisticsControllerGetPublishedStoriesCountByPeriodParams} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const statisticsControllerGetOnlineUsersCount = (signal?: AbortSignal) => {
    return axiosInstance<Number>({url: `/public/statistics/online-users-count`, method: 'get', signal});
};

export const getStatisticsControllerGetOnlineUsersCountQueryKey = () => [`/public/statistics/online-users-count`];

export type StatisticsControllerGetOnlineUsersCountQueryResult = NonNullable<Awaited<ReturnType<typeof statisticsControllerGetOnlineUsersCount>>>;
export type StatisticsControllerGetOnlineUsersCountQueryError = unknown;

export const useStatisticsControllerGetOnlineUsersCount = <
    TData = Awaited<ReturnType<typeof statisticsControllerGetOnlineUsersCount>>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof statisticsControllerGetOnlineUsersCount>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStatisticsControllerGetOnlineUsersCountQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof statisticsControllerGetOnlineUsersCount>>> = ({signal}) =>
        statisticsControllerGetOnlineUsersCount(signal);

    const query = useQuery<Awaited<ReturnType<typeof statisticsControllerGetOnlineUsersCount>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const statisticsControllerGetPublishedStoriesCount = (signal?: AbortSignal) => {
    return axiosInstance<Number>({url: `/public/statistics/published-stories-count`, method: 'get', signal});
};

export const getStatisticsControllerGetPublishedStoriesCountQueryKey = () => [`/public/statistics/published-stories-count`];

export type StatisticsControllerGetPublishedStoriesCountQueryResult = NonNullable<
    Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCount>>
>;
export type StatisticsControllerGetPublishedStoriesCountQueryError = unknown;

export const useStatisticsControllerGetPublishedStoriesCount = <
    TData = Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCount>>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCount>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStatisticsControllerGetPublishedStoriesCountQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCount>>> = ({signal}) =>
        statisticsControllerGetPublishedStoriesCount(signal);

    const query = useQuery<Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCount>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const statisticsControllerGetPublishedStoriesCountByPeriod = (
    params: StatisticsControllerGetPublishedStoriesCountByPeriodParams,
    signal?: AbortSignal
) => {
    return axiosInstance<Number>({url: `/public/statistics/published-stories-count-by-period`, method: 'get', params, signal});
};

export const getStatisticsControllerGetPublishedStoriesCountByPeriodQueryKey = (
    params: StatisticsControllerGetPublishedStoriesCountByPeriodParams
) => [`/public/statistics/published-stories-count-by-period`, ...(params ? [params] : [])];

export type StatisticsControllerGetPublishedStoriesCountByPeriodQueryResult = NonNullable<
    Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCountByPeriod>>
>;
export type StatisticsControllerGetPublishedStoriesCountByPeriodQueryError = unknown;

export const useStatisticsControllerGetPublishedStoriesCountByPeriod = <
    TData = Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCountByPeriod>>,
    TError = unknown
>(
    params: StatisticsControllerGetPublishedStoriesCountByPeriodParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCountByPeriod>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStatisticsControllerGetPublishedStoriesCountByPeriodQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCountByPeriod>>> = ({signal}) =>
        statisticsControllerGetPublishedStoriesCountByPeriod(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCountByPeriod>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
