import React, {useState} from 'react';
import {Container, Tab} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {RatingTable} from './RatingTable';
import {
    getStoryAuthorControllerFindAllWithStoriesCountQueryKey,
    storyAuthorControllerFindAllWithStoriesCount,
    storyAuthorControllerFindAllWithStoryLikesCount
} from 'api/story-author';
import {getNotificationControllerGetAllQueryKey} from 'api/notification';
import {StoryAuthorWithStoriesCountDto, StoryAuthorWithStoryLikesCountDto} from 'api/mDDPublic.schemas';

const TEXT_POPULARITY = (
    <>
        Самые популярные, вдохновляющие и добрые истории, которые должен прочесть каждый!
        <br />
        <br /> Читайте истории и голосуйте за понравившиеся примеры искреннего сервиса, именно ваша активность и оценки
        «Мне нравится» влияют на данный рейтинг.
        <br />
        <br /> Будьте активны, и вы убедитесь, что нам всем есть чем гордиться!!!
    </>
);

const TEXT_AUTHORS = (
    <>
        Самые активные, отзывчивые и готовые рассказать о своих поступках авторы историй!
        <br />
        <br /> Делитесь с коллегами каждым примером искреннего сервиса из своих рабочих и жизненных ситуаций. Возможно,
        именно ваши истории послужат примером или подсказкой в решении вопросов вашим коллегам. А еще вы можете стать
        лидером рейтинга.
        <br />
        <br /> Каждый пример искреннего сервиса – это капля, из которой складывается море добра в нашей жизни. Вместе мы
        сможем превратить это море в океан!
    </>
);
const COLUMNS_POPULARITY = [
    {
        label: 'Имя и фамилия сотрудника',
        dataKey: 'fio',
        value: (data: StoryAuthorWithStoryLikesCountDto) => data?.lastName + ' ' + data?.firstName
    },
    {
        label: 'Отметки нравится',
        dataKey: 'likesCount',
        value: (data: StoryAuthorWithStoryLikesCountDto) => data?.likesCount,
        numeric: true
    }
];
const COLUMNS_AUTHORS = [
    {
        label: 'Имя и фамилия сотрудника',
        dataKey: 'fio',
        value: (data: StoryAuthorWithStoriesCountDto) => data?.lastName + ' ' + data?.firstName
    },
    {
        label: 'Количество историй',
        dataKey: 'storiesCount',
        value: (data: StoryAuthorWithStoriesCountDto) => data?.storiesCount,
        numeric: true
    }
];

export enum RATING_TAB {
    POPULARITY = 'popularity',
    AUTHORS = 'authors'
}

const TABS = [
    {
        title: 'Рейтинг популярности',
        value: RATING_TAB.POPULARITY,
        component: (
            <RatingTable
                text={TEXT_POPULARITY}
                columns={COLUMNS_POPULARITY}
                queryProps={{
                    query: storyAuthorControllerFindAllWithStoryLikesCount,
                    queryKey: getNotificationControllerGetAllQueryKey
                }}
            />
        )
    },
    {
        title: 'Рейтинг авторов',
        value: RATING_TAB.AUTHORS,
        component: (
            <RatingTable
                text={TEXT_AUTHORS}
                columns={COLUMNS_AUTHORS}
                queryProps={{
                    query: storyAuthorControllerFindAllWithStoriesCount,
                    queryKey: getStoryAuthorControllerFindAllWithStoriesCountQueryKey
                }}
            />
        )
    }
];
export const RatingTabs = () => {
    const [tab, setTab] = useState(RATING_TAB.POPULARITY);
    const handleChange = (value: RATING_TAB) => {
        setTab(value);
    };

    return (
        <Container>
            <TabContext value={tab}>
                <TabList onChange={(e, value) => handleChange(value)} sx={{py: 6}}>
                    {TABS.map((item, index) => (
                        <Tab
                            sx={{maxWidth: 'fit-content'}}
                            key={index}
                            label={item.title}
                            value={item.value}
                            disableRipple
                        />
                    ))}
                </TabList>
                {TABS.map((item, index) => (
                    <TabPanel key={index} value={item.value} sx={{p: 0}}>
                        {item.component}
                    </TabPanel>
                ))}
            </TabContext>
        </Container>
    );
};
