/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions, QueryFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {PaginatedDepartment, DepartmentControllerFindAllUniqueParams} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const departmentControllerFindAllUnique = (params?: DepartmentControllerFindAllUniqueParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedDepartment>({url: `/public/department/unique`, method: 'get', params, signal});
};

export const getDepartmentControllerFindAllUniqueQueryKey = (params?: DepartmentControllerFindAllUniqueParams) => [
    `/public/department/unique`,
    ...(params ? [params] : [])
];

export type DepartmentControllerFindAllUniqueQueryResult = NonNullable<Awaited<ReturnType<typeof departmentControllerFindAllUnique>>>;
export type DepartmentControllerFindAllUniqueQueryError = unknown;

export const useDepartmentControllerFindAllUnique = <TData = Awaited<ReturnType<typeof departmentControllerFindAllUnique>>, TError = unknown>(
    params?: DepartmentControllerFindAllUniqueParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof departmentControllerFindAllUnique>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getDepartmentControllerFindAllUniqueQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof departmentControllerFindAllUnique>>> = ({signal}) =>
        departmentControllerFindAllUnique(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof departmentControllerFindAllUnique>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
