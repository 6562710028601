import React from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import Navbar from './Navbar';
import {Box, Container, Fab} from '@mui/material';
import {Add as AddIcon, ArrowUpward as ArrowUpwardIcon} from '@mui/icons-material';
import {useScrollVisible} from 'hooks/useScrollVisible';
import {ROUTES} from 'utils';
import {StoryModal} from 'components/shared/Stories/StoryModal';

const Layout = () => {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    return (
        <>
            <Navbar />
            <Box sx={{paddingBottom: 6}}>
                <Outlet />
            </Box>
            <Container sx={{position: 'relative', display: 'flex', justifyContent: 'flex-end'}}>
                {useScrollVisible() && (
                    <Fab
                        sx={{position: 'fixed', bottom: 90}}
                        onClick={() =>
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            })
                        }>
                        <ArrowUpwardIcon />
                    </Fab>
                )}
                {!pathname.includes(ROUTES.CREATE) && !pathname.includes(ROUTES.SIGN_IN) && (
                    <Fab sx={{position: 'fixed', bottom: 24}} color='primary' onClick={() => navigate(ROUTES.CREATE)}>
                        <AddIcon />
                    </Fab>
                )}
            </Container>
            <StoryModal />
        </>
    );
};

export default Layout;
