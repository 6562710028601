import {FormAutocompleteAsyncPaginate} from 'components/form/FormAutocompleteAsyncPaginate';
import {useInfiniteQuery} from '@tanstack/react-query';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AutocompleteInputChangeReason} from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import {flatInfinityQuery} from 'utils';

type HeaderSearchProps = {
    queryProps: {query: any; queryKey: any};
    name: string;
    placeholder: string;
    optionText: string;
    filter?: any;
    additionalValues?: string[];
};
export const HeaderSearchAutocomplete = ({
    queryProps,
    name,
    placeholder,
    optionText,
    filter,
    additionalValues
}: HeaderSearchProps) => {
    const [search, setSearch] = useState('');
    const {data, fetchNextPage, hasNextPage, refetch} = useInfiniteQuery({
        queryKey: queryProps.queryKey({'filter[search]': search}),
        queryFn: ({pageParam = 0}) =>
            queryProps.query({
                'filter[search]': search,
                'pagination[skip]': pageParam * 50,
                'pagination[take]': 50,
                ...filter
            }),
        getNextPageParam: (lastPage: any) =>
            lastPage?.hasNextPage ? +lastPage?.skip / +lastPage?.take + 1 : undefined,
        select: useCallback(
            (data: any) => ({
                pages: data?.pages.map((i: any) => ({
                    ...i,
                    data: i?.data?.map((d: any) => ({
                        id: d.id,
                        [optionText]: d?.[optionText],
                        other: d,
                        ...additionalValues?.reduce((a, v) => ({...a, [v]: d?.[v]}), {})
                    }))
                })),
                pageParams: data?.pageParams
            }),
            []
        )
    });
    const dataSearch = useMemo(() => flatInfinityQuery(data), [data]);
    const onInputChange = (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === 'reset') {
            setSearch('');
        } else {
            setSearch(`${value || ''}`);
        }
    };
    //TODO: Не отрабатывает( upd. Только так норм
    useEffect(() => {
        if (filter) {
            setTimeout(refetch, 100);
        }
    }, [filter]);

    return (
        <FormAutocompleteAsyncPaginate
            color='secondary'
            name={name}
            placeholder={placeholder}
            options={dataSearch}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            optionText={optionText}
            onInputChange={onInputChange}
        />
    );
};
