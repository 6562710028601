import {typography} from './typography';
import {alpha, darken, lighten, ThemeOptions} from '@mui/material';
// noinspection ES6UnusedImports
import {} from '@mui/lab/themeAugmentation';
import {MODE} from './index';

declare module '@mui/material/styles' {
    interface TypeBackground {
        card?: string;
        secondary?: string;
        secondaryConst?: string;
        stripe?: string;
    }

    interface TypeText {
        primaryConst?: string;
    }

    interface Palette {
        neutral: Palette['primary'];
    }

    interface PaletteOptions {
        neutral: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}
export const baseTheme: ThemeOptions = {
    typography,
    shape: {
        borderRadius: 5
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1000,
            lg: 1200,
            xl: 1536
        }
    },
    components: {
        MuiBadge: {
            styleOverrides: {
                badge: {fontSize: 12, width: 15, height: 15, minWidth: 15}
            }
        },
        MuiCard: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    border: '3px solid #f4f4f4',
                    '& .MuiTypography-root': {
                        color: '#2C2A29'
                    },
                    borderRadius: 10
                }
            }
        },
        MuiFab: {
            styleOverrides: {
                primary: ({theme}) => ({
                    '&:hover': {
                        background: theme.palette.secondary.main
                    }
                })
            }
        },
        MuiTableRow: {
            styleOverrides: {
                head: ({theme}) => ({
                    backgroundColor: theme.palette.background.default
                })
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: ({theme}) => ({
                    '&.MuiTableCell-head': {
                        backgroundColor: theme.palette.background.stripe
                    }
                }),
                head: ({theme}) => ({
                    ...theme.typography.body2,
                    fontWeight: 'bold'
                })
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {fontSize: '14px'}
            }
        },
        MuiButton: {
            variants: [
                {
                    props: {variant: 'contained', color: 'neutral'},
                    style: {
                        backgroundColor: '#f4f4f4',
                        boxShadow: 'none !important',
                        color: '#767575',
                        '&:hover': {
                            backgroundColor: darken('#f4f4f4', 0.1)
                        }
                    }
                },
                {
                    props: {variant: 'outlined', color: 'neutral'},
                    style: ({theme}) => ({
                        fontWeight: 500,
                        borderWidth: 2,
                        color: theme.palette.mode === MODE.DARK ? '#F4F5F9' : '#767575',
                        borderColor: lighten(theme.palette.mode === MODE.DARK ? '#F4F5F9' : '#767575', 0.5),
                        '&:hover': {
                            borderWidth: 2
                        }
                    })
                }
            ],
            styleOverrides: {
                root: {
                    lineHeight: 1.25,
                    padding: '14px 18px'
                },
                containedPrimary: ({theme}) => ({
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: darken(theme.palette.primary.main, 0.1)
                    }
                }),
                containedSecondary: ({theme}) => ({
                    backgroundColor: theme.palette.secondary.main,
                    '&:hover': {
                        backgroundColor: darken(theme.palette.secondary.main, 0.1)
                    }
                })
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({theme}) => ({
                    color: theme.palette.primary.dark,
                    backgroundColor: theme.palette.background.secondaryConst,
                    border: `1px solid #ced4da`,
                    overflow: 'hidden',
                    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none !important'
                    },
                    '&.Mui-error': {
                        border: '1px solid ' + theme.palette.error.main + '!important'
                    },
                    '&:not(.Mui-error).Mui-focused': {
                        border: '1px solid #86B7FE',
                        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
                        boxShadow: `${alpha('#1976d2', 0.25)} 0 0 0 0.2rem`
                    },
                    '&.Mui-error.Mui-focused': {
                        border: '1px solid ' + theme.palette.error.main,
                        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
                        boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`
                    }
                }),
                notchedOutline: {
                    border: 'none'
                },
                colorSecondary: ({theme}) => ({
                    backgroundColor: theme.palette.primary.contrastText
                })
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                endAdornment: {
                    '& .MuiSvgIcon-root': {
                        fill: 'grey'
                    }
                },
                listbox: ({theme}) => ({color: theme.palette.primary.dark}),
                root: ({theme}) => ({
                    '& .MuiChip-root': {
                        color: theme.palette.primary.dark,
                        backgroundColor: 'rgba(0, 0, 0, 0.08)'
                    }
                })
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: '24px',
                    '& .MuiChip-deleteIcon': {
                        color: 'rgba(44, 42, 41, 0.26)',
                        '&:hover': {
                            color: 'rgb(153,152,151)'
                        }
                    }
                }
            }
        },

        MuiTabs: {
            styleOverrides: {
                scrollableX: {
                    '& .MuiTabs-flexContainer': {
                        flexWrap: 'nowrap'
                    }
                },
                flexContainer: {
                    flexWrap: 'wrap'
                    // justifyContent: 'space-between'
                },
                indicator: {
                    display: 'none'
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: ({theme}) => ({
                    '&.MuiToggleButton-primary': {
                        padding: '0 0.5rem',
                        background: '#9e9e9e',
                        borderRadius: '2px !important',
                        border: 'none',
                        fontSize: '13px',
                        lineHeight: '20px',
                        color: '#FFFFFF',
                        '&.Mui-selected': {
                            background: theme.palette.primary.main
                        }
                    },
                    '&.MuiToggleButton-standard': {
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.54)',
                        '&.Mui-selected': {
                            color: '#2c2a29',
                            backgroundColor: 'rgba(44, 42, 41, 0.08)'
                        }
                    }
                })
            }
        },
        MuiTab: {
            styleOverrides: {
                root: ({theme}) => ({
                    textTransform: 'uppercase',
                    fontFamily: 'Circe',
                    fontSize: '26px',
                    lineHeight: '30px',
                    color: theme.palette.text.secondary,
                    padding: '0.5rem 1rem',
                    '&:hover': {
                        color: theme.palette.text.primary
                    },
                    '&.Mui-selected': {
                        color: theme.palette.text.primary
                    }
                })
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    bottom: '-22px'
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none'
                }
            }
        },
        MuiLoadingButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-outlinedNeutral': {
                        borderWidth: 2
                    }
                }
            }
        }
    }
};
