import {ThemeOptions} from '@mui/material';

export const blackTheme: ThemeOptions = {
    palette: {
        primary: {
            main: '#e04e39',
            dark: '#2c2a29',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#c39367',
            dark: '#D3D2D2',
            contrastText: '#ffffff'
        },
        background: {
            default: '#2C2A29',
            paper: '#fcfcfc',
            card: '#51504F',
            secondary: '#2C2A29',
            stripe: '#4A4948',
            secondaryConst: '#F4F4F4'
        },
        neutral: {
            main: '#767575',
            contrastText: '#fff'
        },
        text: {
            primary: '#F4F5F9',
            secondary: '#51504F',
            primaryConst: '#2c2a29'
        },
        error: {
            main: '#dc3545'
        },
        mode: 'dark'
    }
};
