import React from 'react';
import {Box, IconButton, TextField, TextFieldProps, Tooltip} from '@mui/material';
import {useController, UseControllerProps} from 'react-hook-form';
import {ErrorOutline as ErrorOutlineIcon} from '@mui/icons-material';
import {Labeled} from './Labeled';
import {CloseIcon} from 'assets/icons';
import {useQsParams} from 'hooks';

type FormTextFieldType = {label?: string; isFilter?: boolean; maxLength?: number};
export const FormTextField = (props: UseControllerProps & Omit<TextFieldProps, 'label'> & FormTextFieldType) => {
    const {name, rules, placeholder, label, isFilter, maxLength, ...rest} = props;
    const {
        field,
        fieldState: {error}
    } = useController({name, rules, defaultValue: ''});
    const [params, setParams] = useQsParams();

    return (
        <Labeled label={label}>
            <TextField
                {...field}
                fullWidth
                value={field.value || ''}
                // helperText={error?.message}
                placeholder={placeholder || label}
                inputProps={{maxLength: maxLength || undefined}}
                error={!!error}
                InputProps={{
                    endAdornment:
                        isFilter && field.value ? (
                            <IconButton
                                sx={{'& path': {fill: 'grey'}, mr: -1}}
                                onClick={() => {
                                    field.onChange('');
                                    setParams({...params, ...{[name]: ''}});
                                }}>
                                <CloseIcon width={12} height={12} />
                            </IconButton>
                        ) : (
                            error && (
                                <Tooltip title={error?.message?.trim() ? error.message : ''}>
                                    <ErrorOutlineIcon color='error' />
                                </Tooltip>
                            )
                        )
                }}
                {...rest}
            />
            {maxLength && (
                <Box color='neutral.main' sx={{position: 'absolute', bottom: -20, right: 0, fontSize: 12}}>
                    {field?.value?.length + ' / ' + maxLength}
                </Box>
            )}
        </Labeled>
    );
};
