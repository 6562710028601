import React from 'react';
import bg from 'assets/images/bg.svg';
import {Box, Button, Container, Grid, Theme, Typography, useMediaQuery} from '@mui/material';
import {LogoIcon, PlusIcon} from 'assets/icons';
import {ROUTES} from 'utils';
import {useNavigate} from 'react-router-dom';
import {RatingSearchInputs} from 'components/shared/HeaderSearch/RatingSearchInputs';
import {StoriesSearchInputs} from 'components/shared/HeaderSearch/StoriesSearchInputs';

export const HeaderSearch = ({rating}: {rating?: boolean}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const navigate = useNavigate();

    return (
        <>
            <Box
                sx={{
                    background: `url(${bg}) no-repeat top center`,
                    backgroundSize: 'cover',
                    minHeight: '443px',
                    padding: '53px 0 20px',
                    position: 'relative',
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: isMd ? '' : 'rgba(0,0,0,0.3)'
                    }
                }}>
                <Container sx={{position: 'relative', zIndex: 2}}>
                    <Grid container sx={{justifyContent: 'space-between'}}>
                        <Grid item xs={12} md={6} lg={4}>
                            <LogoIcon />
                            <Typography variant="h1" mt={2}
                                        sx={{color: (theme) => theme.palette.secondary.contrastText}}>
                                Мои
                            </Typography>
                            <Typography
                                variant="h1"
                                mb={4.5}
                                sx={{
                                    color: (theme) => theme.palette.secondary.contrastText,
                                    fontSize: '42px',
                                    lineHeight: '42px',
                                    fontWeight: 'bold'
                                }}>
                                Добрые дела
                            </Typography>
                            <Typography
                                variant="h2"
                                mb={isMd ? 4 : 3}
                                sx={{
                                    fontSize: isMd ? '26px' : '24px',
                                    color: (theme) => (isMd ? theme.palette.primary.main : theme.palette.primary.contrastText)
                                }}>
                                Здесь происходит чудо
                            </Typography>
                            <Typography
                                variant="body2"
                                mb={5}
                                sx={{
                                    color: (theme) => theme.palette.secondary.contrastText
                                }}>
                                Поделись своей историей и узнавай новости каждый день
                            </Typography>
                        </Grid>
                        {isMd && (
                            <Grid item xs={3} alignItems="center" display="flex">
                                <Button
                                    disableRipple
                                    onClick={() => navigate(ROUTES.CREATE)}
                                    sx={{
                                        flexDirection: 'column',
                                        textAlign: 'left',
                                        alignItems: 'flex-start',
                                        paddingY: 1,
                                        paddingX: 3
                                    }}>
                                    <PlusIcon />
                                    <Typography variant="h2" mt={3}
                                                sx={{color: (theme) => theme.palette.secondary.contrastText}}>
                                        Поделиться историей
                                    </Typography>
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    {rating ? <RatingSearchInputs /> : <StoriesSearchInputs />}
                </Container>
            </Box>
        </>
    );
};
