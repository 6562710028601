import {FormAutocompleteAsyncPaginate} from 'components/form/FormAutocompleteAsyncPaginate';
import {useInfiniteQuery} from '@tanstack/react-query';
import React, {useMemo, useState} from 'react';
import {AutocompleteInputChangeReason} from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import {getTagControllerFindAllQueryKey, tagControllerFindAll} from 'api/tag';
import {flatInfinityQuery} from 'utils';

export const TagsSearch = () => {
    const [search, setSearch] = useState('');

    const {data, fetchNextPage, hasNextPage} = useInfiniteQuery({
        queryKey: getTagControllerFindAllQueryKey({'filter[name]': search}),
        queryFn: ({pageParam = 0}) =>
            tagControllerFindAll({
                'filter[name]': search,
                'pagination[skip]': pageParam * 50,
                'pagination[take]': 50
            }),
        getNextPageParam: (lastPage) => (lastPage?.hasNextPage ? +lastPage?.skip / +lastPage?.take + 1 : undefined)
    });
    const tags = useMemo(() => flatInfinityQuery(data), [data]);
    const onInputChange = (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === 'reset') {
            setSearch('');
        } else {
            setSearch(`%${value || ''}%`);
        }
    };
    return (
        <FormAutocompleteAsyncPaginate
            name='tags'
            label='Хештеги'
            placeholder='Выберите теги'
            options={tags}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            optionText='name'
            multiple
            onInputChange={onInputChange}
        />
    );
};
