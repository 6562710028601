import React, {useCallback, useRef, useState} from 'react';
import {Box, Button, ButtonProps, Stack, Theme, Typography, useMediaQuery} from '@mui/material';
import {CreatePlusIcon} from 'assets/icons';
import {useDropzone} from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import {CustomModal} from 'components';
import {ModalProps, useModal} from 'hooks';
import {SelectMedia} from './SelectMedia';
import {onCreateCroppedPreview} from './onCreateCroppedPreview';

const PropsButton: ButtonProps = {
    variant: 'contained',
    sx: {padding: '6px 12px', fontWeight: 'normal'}
};
type PhotoModalType = {
    setPhoto: any;
};

export const AddPhotoModal = ({open, handleToggle, setPhoto}: ModalProps & PhotoModalType) => {
    const {open: openMedia, handleToggle: handleToggleMedia} = useModal();
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [file, setFile] = useState<any>();
    const [fileName, setFileName] = useState<string>('');
    const [media, setMedia] = useState<any>();
    const [crop, setCrop] = useState<any>({unit: '%', width: 100, aspect: '1'});
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const ext = '.pdf, .png, .jpeg, .jpg, .bmp, .tiff,  .heic, .heif';
    const uploadNewAvatar = (cropFile: any) => {
        setPhoto(cropFile);
        handleToggle();
        clear();
    };
    const onDrop = useCallback((acceptedFiles: any) => {
        setFileName(acceptedFiles?.[0].name);
        const reader = new FileReader();
        reader.addEventListener('load', () => setFile(reader.result));
        reader.readAsDataURL(acceptedFiles[0]);
        setMedia(null);
    }, []);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: ext
    });
    const onLoad = useCallback((img: any) => {
        imgRef.current = img;
    }, []);
    const clear = () => {
        setMedia(null);
        setFile(null);
    };
    return (
        <CustomModal hiddenCloseIcon open={open} onClose={handleToggle} maxWidth='md'>
            <Box sx={{p: 2, border: '1px solid #dee2e6'}}>
                {file || media ? (
                    <Box
                        sx={{
                            width: '100%',
                            height: 340,
                            maxWidth: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        {media ? (
                            <Box
                                width='100%'
                                height='100%'
                                src={media?.file?.url}
                                component='img'
                                sx={{
                                    objectFit: 'contain',
                                    maxWidth: 340
                                }}
                            />
                        ) : (
                            <ReactCrop
                                src={file}
                                crop={crop}
                                onChange={(c: any) => setCrop(c)}
                                onImageLoaded={onLoad}
                                onComplete={(c: any) => setCompletedCrop(c)}
                                imageStyle={{
                                    maxHeight: '340px'
                                }}
                            />
                        )}
                    </Box>
                ) : (
                    <Button
                        variant='contained'
                        color='inherit'
                        {...getRootProps()}
                        sx={{
                            mx: 'auto',
                            width: '100%',
                            height: 340,
                            maxWidth: '100%',
                            p: 0,
                            color: 'text.primaryConst',
                            background: '#f4f4f4 !important',
                            boxShadow: 'none !important'
                        }}>
                        <Stack sx={{alignItems: 'center'}} spacing={3}>
                            <CreatePlusIcon />
                            <Typography sx={{mt: '16px !important'}} color='text.primaryConst'>
                                Добавьте фото
                            </Typography>
                        </Stack>
                    </Button>
                )}
                <input {...getInputProps()} accept='image/*' />
            </Box>
            <canvas ref={previewCanvasRef} style={{display: 'none'}} />
            <Stack direction={isMd ? 'row' : 'column'} justifyContent='space-between' sx={{p: 2}} spacing={1}>
                <Stack direction={isMd ? 'row' : 'column'} spacing={1}>
                    <Button {...PropsButton} onClick={handleToggleMedia}>
                        Фото с базы портала
                    </Button>
                    <Button {...PropsButton} {...getRootProps({})}>
                        Фото с устройства
                    </Button>
                </Stack>
                <Stack direction={isMd ? 'row' : 'column'} spacing={1}>
                    <Button {...PropsButton} onClick={handleToggle}>
                        Назад
                    </Button>
                    <Button {...PropsButton} onClick={clear}>
                        Очистить
                    </Button>
                    <Button
                        {...PropsButton}
                        onClick={() =>
                            file && !media
                                ? onCreateCroppedPreview({
                                      completedCrop,
                                      previewCanvasRef,
                                      imgRef,
                                      uploadNewAvatar,
                                      fileName
                                  })
                                : uploadNewAvatar(media ? media : undefined)
                        }>
                        Сохранить
                    </Button>
                </Stack>
            </Stack>
            <SelectMedia open={openMedia} handleToggle={() => handleToggleMedia()} setMedia={setMedia} />
        </CustomModal>
    );
};
