import React, {useMemo} from 'react';
import {Box, IconButton, Stack, styled, Tooltip, Typography} from '@mui/material';
import {ViewIcon} from 'assets/icons';
import moment from 'moment';
import {CreateOrRemoveLikeDtoType, PublicOneStoryDto} from 'api/mDDPublic.schemas';
import {getUserName} from 'utils';
import {useLikeControllerCreate, useLikeControllerRemove} from 'api/like';
import {StyledCommentIcon, StyledHeartIcon} from '../components';
import {useQueryClient} from '@tanstack/react-query';
import {getStoryControllerFindOneQueryKey, getStoryControllerGetStoryCommentsQueryKey, useStoryControllerGetStoryComments} from 'api/story';
import {useQsParams} from 'hooks';

enum ACTION {
    REMOVE = 'REMOVE',
    ADD = 'ADD'
}

export const Comments = ({story}: {story: PublicOneStoryDto}) => {
    const queryClient = useQueryClient();
    const [params] = useQsParams();
    const {storyId} = params;
    const {data} = useStoryControllerGetStoryComments(storyId);
    const comments = data?.data || [];
    const addLikeMutation = useLikeControllerCreate();
    const removeLikeMutation = useLikeControllerRemove();

    const toggleLike = (id: number, type: CreateOrRemoveLikeDtoType, action: ACTION) => {
        (action === ACTION.ADD ? addLikeMutation : removeLikeMutation).mutateAsync({data: {id, type}}).then(() => {
            if (type === CreateOrRemoveLikeDtoType.STORY) {
                queryClient.refetchQueries(getStoryControllerFindOneQueryKey(storyId));
            }
            if (type === CreateOrRemoveLikeDtoType.COMMENT) {
                queryClient.refetchQueries(getStoryControllerGetStoryCommentsQueryKey(storyId));
            }
        });
    };

    const firstsViewers = useMemo(
        () =>
            story?.firstsViewers.length > 0
                ? `${story?.firstsViewers.map((el) => el?.user?.firstName + ' ' + el?.user?.lastName).join('\n')}${
                      story?.viewsCount > 5 ? '\n...' : ''
                  }`
                : undefined,
        [story?.firstsViewers]
    );
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: {xs: 'flex-start', md: 'center'},
                    flexDirection: {xs: 'column', md: 'row'}
                }}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                    <Tooltip title='Мне нравится'>
                        <IconButton
                            onClick={() => toggleLike(story.id, CreateOrRemoveLikeDtoType.STORY, story?.isLiked ? ACTION.REMOVE : ACTION.ADD)}>
                            <StyledHeartIcon isLiked={story?.isLiked} />
                        </IconButton>
                    </Tooltip>

                    <Typography sx={{mr: 1, mb: 0.5, ml: -1}}>{story?.likesCount}</Typography>
                    <StyledCommentIcon />
                    <Typography sx={{mb: 0.5, mr: 1}}>{story?.commentsCount}</Typography>
                    <Tooltip title={firstsViewers || ''} slotProps={{tooltip: {sx: {whiteSpace: 'pre-wrap'}}}} arrow>
                        <StyledViewIcon />
                    </Tooltip>

                    <Typography sx={{mb: 0.5}}>{story?.viewsCount}</Typography>
                </Box>
                <Typography variant='h2' sx={{ml: 'auto'}}>
                    Комментарии
                </Typography>
            </Box>
            <Stack sx={{gap: 1, mt: '16px !important'}}>
                {comments.map((comment, index) => (
                    <Box key={index}>
                        <Typography variant='body2' sx={{fontWeight: 'bold'}} mb={1}>
                            {getUserName(comment.author)}
                        </Typography>
                        <Box
                            sx={{
                                borderRadius: '5px',
                                p: 1.75,
                                background: (theme) => theme.palette.background.secondary
                            }}>
                            <Typography>{comment.text}</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontStyle: 'italic',
                                mb: 2
                            }}>
                            <Typography sx={{color: '#767575'}}>{moment(comment.createdAt).format('DD.MM.YYYY')}</Typography>
                            <Stack direction='row' alignItems='center'>
                                <IconButton
                                    onClick={() =>
                                        toggleLike(comment.id, CreateOrRemoveLikeDtoType.COMMENT, comment?.isLiked ? ACTION.REMOVE : ACTION.ADD)
                                    }>
                                    <StyledHeartIcon isLiked={comment.isLiked} />
                                </IconButton>
                                <Typography sx={{mb: 0.5}}>{comment.likesCount}</Typography>
                            </Stack>
                        </Box>
                    </Box>
                ))}
            </Stack>
        </>
    );
};

const StyledViewIcon = styled(ViewIcon)({
    path: {
        fill: '#d3d2d2'
    }
});
