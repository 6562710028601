import React from 'react';
import {Box, IconButton, Paper, Stack, Typography} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';
import {StyledCommentIcon, StyledHeartIcon} from '../Stories/components';
import {useQsParams} from 'hooks';
import {getNotificationControllerGetAllQueryKey, useNotificationControllerViewNotification} from 'api/notification';
import {Notification as NotificationEntity} from 'api/mDDPublic.schemas';
import moment from 'moment';
import {useQueryClient} from '@tanstack/react-query';

const Notification = ({data, isNavigation}: {data: NotificationEntity; isNavigation?: boolean}) => {
    const [params, setParams] = useQsParams();
    const queryClient = useQueryClient();
    const VARIABLES = {
        STORY_LIKE: {
            icon: <StyledHeartIcon isLiked={true} />,
            action: 'Оценил(a) вашу новость:',
            author: data?.like?.user,
            story: data?.like?.story
        },
        STORY_COMMENT: {
            icon: <StyledCommentIcon />,
            action: 'Прокомментировал(а) вашу новость:',
            author: data?.comment?.author,
            story: data?.comment?.story
        },
        COMMENT_LIKE: {
            icon: <StyledHeartIcon isLiked />,
            action: 'Оценил(а) ваш комментарий в истории:',
            author: data?.like?.user,
            story: data?.like?.comment?.story
        }
    };
    const typeInformation = VARIABLES[data?.type as keyof typeof VARIABLES];
    const addToViewedMutation = useNotificationControllerViewNotification();
    const addToViewed = () => {
        addToViewedMutation
            .mutateAsync({data: {notificationId: data?.id}})
            .then(() => queryClient.refetchQueries(getNotificationControllerGetAllQueryKey({})));
    };
    return (
        <>
            <Paper variant='outlined' sx={{p: 1.5, backgroundColor: (theme) => theme.palette.background.card}}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                    {typeInformation?.icon}
                    <Typography variant='button'>{typeInformation?.author.lastName + ' ' + typeInformation?.author.firstName}</Typography>
                    {isNavigation && (
                        <IconButton sx={{ml: 'auto'}} onClick={addToViewed}>
                            <CloseIcon />
                        </IconButton>
                    )}
                </Box>
                <Stack sx={{mt: 1, cursor: 'pointer'}} onClick={() => setParams({...params, storyId: typeInformation.story.id})}>
                    <Typography variant='subtitle2'>{moment(data?.createdAt).locale('ru').format('DD MMM HH:mm')}</Typography>
                    <Typography>{typeInformation?.action}</Typography>
                    <Typography sx={{fontWeight: 600}}>{typeInformation?.story?.title}</Typography>
                </Stack>
            </Paper>
        </>
    );
};

export default Notification;
