import React, {useMemo} from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {getStoryControllerFindAllQueryKey, storyControllerFindAll} from 'api/story';
import {useQsParams} from 'hooks';
import {useLocation, useParams} from 'react-router-dom';
import {CATEGORIES_TAB, flatInfinityQuery, ROUTES} from 'utils';
import {useInfiniteQuery} from '@tanstack/react-query';
import {StoryControllerFindAllParams} from 'api/mDDPublic.schemas';
import {CircularLoading} from 'components';
import moment from 'moment';
import {StoryCard} from './StoryCard';
import {LoadingButton} from '@mui/lab';

export const Stories = () => {
    const [params] = useQsParams();
    const {category} = useParams();
    const location = useLocation();
    const {search, date, tagIds, department} = params;
    const query: StoryControllerFindAllParams = {
        'order[by]': category === CATEGORIES_TAB.BEST ? 'like' : 'created_at',
        'order[direction]': 'DESC',
        'filter[search]': search,
        'filter[departmentAbbreviation]': department?.abbreviation,
        'filter[dateFrom]': date?.[0] || undefined,
        'filter[dateTo]': date?.[1] ? moment(date?.[1]).endOf('d').toISOString() : undefined,
        'filter[isViewed]': category === CATEGORIES_TAB.VIEWED ? 'true' : category === CATEGORIES_TAB.NOT_VIEWED ? 'false' : undefined,
        'filter[isFavorite]': location.pathname === ROUTES.FAVORITE ? 'true' : undefined,
        'filter[isLiked]': location.pathname === ROUTES.LIKED ? 'true' : undefined,
        'filter[tagIds]': tagIds ? tagIds.map((i: string) => +i) : undefined,
        'filter[isSelf]': location.pathname === ROUTES.PROFILE ? 'true' : undefined
    };

    const {data, isLoading, isFetchingNextPage, isError, fetchNextPage, hasNextPage} = useInfiniteQuery(
        getStoryControllerFindAllQueryKey(query),
        ({pageParam = 0}) =>
            storyControllerFindAll({
                ...query,
                'pagination[skip]': pageParam * 9,
                'pagination[take]': 9
            }),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => (lastPage?.hasNextPage ? +lastPage?.skip / +lastPage?.take + 1 : undefined)
        }
    );
    const stories = useMemo(() => flatInfinityQuery(data), [data]);
    return (
        <Box sx={{mt: 2}}>
            <CircularLoading isLoading={isLoading || isError}>
                <Grid container direction='row' spacing={2.5} rowSpacing={8}>
                    {stories?.map((story, index) => (
                        <Grid key={story?.id + '-' + index} item xs={12} md={6} lg={4}>
                            <StoryCard data={story} />
                        </Grid>
                    ))}
                </Grid>
                <Box display='flex' justifyContent='center' mt={6}>
                    {stories && hasNextPage && (
                        <LoadingButton loading={isFetchingNextPage} variant='outlined' color='neutral' fullWidth onClick={() => fetchNextPage()}>
                            Показать ещё
                        </LoadingButton>
                    )}
                    {!stories.length && <Typography>Нет данных</Typography>}
                </Box>
            </CircularLoading>
        </Box>
    );
};
