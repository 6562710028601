import {useController, UseControllerProps} from 'react-hook-form';
import React from 'react';
import {Labeled} from './Labeled';
import {Autocomplete, AutocompleteProps, BaseTextFieldProps, TextField} from '@mui/material';
import {AutocompleteValue} from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import {useQsParams} from 'hooks';

export const FormAutocompleteAsyncPaginate = <T extends {id: number}, M extends boolean | undefined>(
    props: UseControllerProps &
        Omit<AutocompleteProps<T, M, any, any>, 'renderInput'> & {
            label?: string;
            optionText?: keyof T;
            optionValue?: keyof T;
            hasNextPage?: boolean;
            fetchNextPage?: () => void;
            color?: BaseTextFieldProps['color'];
            // saveOnlyValue?: boolean;
        }
) => {
    const {
        name,
        options = [],
        label,
        optionText,
        optionValue = 'id',
        placeholder,
        multiple,
        rules,
        // saveOnlyValue,
        hasNextPage,
        fetchNextPage,
        color,
        ...rest
    } = props;

    const {
        field,
        fieldState: {error}
    } = useController({name, rules, defaultValue: multiple ? [] : null});
    const [params, setParams] = useQsParams();

    return (
        <Labeled label={label}>
            <Autocomplete
                {...field}
                size='small'
                options={options}
                fullWidth
                filterOptions={(x) => x}
                multiple={multiple}
                loadingText='Загрузка...'
                noOptionsText='Нет данных'
                value={multiple ? field.value || [] : field.value || null}
                getOptionLabel={(option: any) => String(option[optionText] || '')}
                isOptionEqualToValue={(option, value) => String(option[optionValue]) === String(value[optionValue])}
                renderInput={(params) => (
                    <TextField error={!!error} type='text' {...params} placeholder={placeholder || label} color={color || 'primary'} />
                )}
                onChange={(_, data: AutocompleteValue<T, M, any, any>, reason) => {
                    field.onChange(data);
                    if (reason === 'clear') setParams({...params, ...{[name]: ''}});
                }}
                ListboxProps={{
                    onScroll: (event: any) => {
                        const listboxNode = event.currentTarget;
                        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                            if (hasNextPage) fetchNextPage?.();
                        }
                    },
                    style: {maxHeight: '300px'}
                }}
                {...rest}
            />
        </Labeled>
    );
};
