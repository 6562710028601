import {styled} from '@mui/material';
import {HeartIcon} from 'assets/icons';

export const StyledHeartIcon = styled(HeartIcon, {
    shouldForwardProp: (prop) => prop !== 'isLiked'
})<{isLiked: boolean}>(({isLiked}) => ({
    path: {
        fill: isLiked ? '#E04E39' : '#E28B7E'
    }
}));
