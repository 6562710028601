import axios, {RawAxiosRequestConfig} from 'axios';
import {onRejected} from 'api/mutator/axios-interceptors';
import {getTokens, ROUTES} from 'utils';

export const AXIOS_INSTANCE = axios.create({withCredentials: true, baseURL: process.env.REACT_APP_API_URL});

export const axiosInstance = <T>(config: RawAxiosRequestConfig): Promise<T> => {
    const source = axios.CancelToken.source();
    const {accessToken} = getTokens();
    const promise = AXIOS_INSTANCE({
        ...config,
        headers: {
            Authorization: 'JWT ' + accessToken
        },
        cancelToken: source.token
    })
        .then(({data}) => data)
        .catch((err) => {
            if (
                (err.response?.status === 401 && window.location.pathname !== ROUTES.SIGN_IN) ||
                (err.response?.status === 403 &&
                    err.response.data.message === 'UNSIGNED_USER_AGREEMENT' &&
                    window.location.pathname !== ROUTES.SIGN_IN &&
                    window.location.pathname !== ROUTES.USER_AGREEMENT)
            ) {
                window.location.href = ROUTES.SIGN_IN + `?redirectUrl=${encodeURIComponent(location.href)}`;
            } else return Promise.reject(err.response?.data);
        });

    // @ts-ignore
    promise.cancel = () => {
        source.cancel('Query was cancelled');
    };

    return promise;
};

AXIOS_INSTANCE.interceptors.response.use(
    (response) => response,
    (error) => onRejected(error)
);
