/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useMutation} from '@tanstack/react-query';
import type {UseMutationOptions, MutationFunction} from '@tanstack/react-query';
import type {BaseResponseType, FeedbackInput} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const feedbackControllerSendFeedbackEmail = (feedbackInput: FeedbackInput) => {
    const formData = new FormData();
    formData.append('title', feedbackInput.title);
    formData.append('description', feedbackInput.description);
    if (feedbackInput.uploadedFile !== undefined) {
        formData.append('uploadedFile', feedbackInput.uploadedFile);
    }

    return axiosInstance<BaseResponseType>({
        url: `/public/feedback`,
        method: 'post',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData
    });
};

export type FeedbackControllerSendFeedbackEmailMutationResult = NonNullable<Awaited<ReturnType<typeof feedbackControllerSendFeedbackEmail>>>;
export type FeedbackControllerSendFeedbackEmailMutationBody = FeedbackInput;
export type FeedbackControllerSendFeedbackEmailMutationError = unknown;

export const useFeedbackControllerSendFeedbackEmail = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof feedbackControllerSendFeedbackEmail>>, TError, {data: FeedbackInput}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof feedbackControllerSendFeedbackEmail>>, {data: FeedbackInput}> = (props) => {
        const {data} = props ?? {};

        return feedbackControllerSendFeedbackEmail(data);
    };

    return useMutation<Awaited<ReturnType<typeof feedbackControllerSendFeedbackEmail>>, TError, {data: FeedbackInput}, TContext>(
        mutationFn,
        mutationOptions
    );
};
