import React, {ReactNode, useEffect} from 'react';
import {useVisitControllerCreate} from 'api/visit';
import platform from 'platform';
import {CATEGORIES_TAB, ROUTES} from 'utils';
import {useQsParams} from 'hooks';
import {useAuthContext} from 'context';

export const Visit = ({children, story}: {children: ReactNode; story?: boolean}) => {
    const visitMutation = useVisitControllerCreate();
    const {me} = useAuthContext();
    const [params] = useQsParams();
    const {storyId} = params;

    useEffect(() => {
        if (
            (Object.values(ROUTES).includes(location.pathname as ROUTES) ||
                Object.values(CATEGORIES_TAB).includes(location.pathname.slice(1) as CATEGORIES_TAB)) &&
            me
        ) {
            visitMutation.mutateAsync({
                data: {
                    link: story && storyId ? location.pathname + `?storyId=` + storyId : location.pathname,
                    browser: platform.name || ''
                }
            });
        }
    }, [location.pathname, me?.id]);

    return <>{children}</>;
};
