import {useForm} from 'react-hook-form';
import {Stack} from '@mui/material';
import {Form, FormTextField} from 'components/form';
import React from 'react';
import {getStoryControllerFindOneQueryKey, getStoryControllerGetStoryCommentsQueryKey, useStoryControllerCreateComment} from 'api/story';
import * as yup from 'yup';
import {ERROR_MESSAGE} from 'utils';
import {yupResolver} from '@hookform/resolvers/yup';
import {useQsParams} from 'hooks';
import {useQueryClient} from '@tanstack/react-query';
import {LoadingButton} from '@mui/lab';

type FormDataProps = {
    comment: string;
};

const schema = yup.object({
    comment: yup.string().required(ERROR_MESSAGE.REQUIRED)
});
export const InputComment = () => {
    const queryClient = useQueryClient();
    const [params] = useQsParams();
    const {storyId} = params;
    const form = useForm<FormDataProps>({
        resolver: yupResolver(schema)
    });
    const {reset} = form;
    const addComment = useStoryControllerCreateComment();
    const onSubmit = (data: FormDataProps) => {
        addComment.mutateAsync({id: storyId.toString(), data: {text: data.comment}}).then(() => {
            queryClient.refetchQueries(getStoryControllerGetStoryCommentsQueryKey(storyId));
            queryClient.refetchQueries(getStoryControllerFindOneQueryKey(storyId));
            reset({comment: ''});
        });
    };

    return (
        <Form form={form} onSubmit={onSubmit}>
            <Stack alignItems='flex-end' sx={{display: 'flex', gap: 3}} flex={1}>
                <FormTextField
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            background: (theme) => theme.palette.background.secondary,
                            color: (theme) => theme.palette.text.primary
                        }
                    }}
                    name='comment'
                    placeholder='Оставьте свой комментарий'
                    multiline
                    rows={4}
                />
                <LoadingButton loading={addComment.isLoading} variant='contained' color='secondary' type='submit'>
                    Комментировать
                </LoadingButton>
            </Stack>
        </Form>
    );
};
