import {Container, Grid, Typography} from '@mui/material';
import {TableScroll} from 'components';
import React, {ReactNode, useMemo} from 'react';
import {useInfiniteQuery} from '@tanstack/react-query';
import {flatInfinityQuery} from 'utils';
import moment from 'moment';
import {useQsParams} from 'hooks';

type Column = {
    label: string;
    dataKey: string;
    value: (data: any) => ReactNode;
    numeric?: boolean;
};
type RatingTableProps = {text: ReactNode; columns: Column[]; queryProps: {query: any; queryKey: any}};
export const RatingTable = ({text, columns, queryProps}: RatingTableProps) => {
    const [params] = useQsParams();
    const {date, department, district, division, territorialUnit} = params;
    const query = {
        'filter[dateFrom]': date?.[0] || undefined,
        'filter[dateTo]': date?.[1] ? moment(date?.[1]).endOf('d').toISOString() : undefined,
        'filter[districtId]': district?.id,
        'filter[divisionId]': division?.id,
        'filter[territorialUnitId]': territorialUnit?.id,
        'filter[departmentId]': department?.id
    };
    const {data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage} = useInfiniteQuery(
        queryProps.queryKey(query),
        ({pageParam = 0}) => queryProps.query({...query, 'pagination[skip]': pageParam * 50, 'pagination[take]': 50}),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => (lastPage?.hasNextPage ? +lastPage?.skip / +lastPage?.take + 1 : undefined)
        }
    );
    const cards = useMemo(() => flatInfinityQuery(data), [data]);
    const loading = isLoading || hasNextPage || isFetchingNextPage;
    return (
        <Container>
            <Grid container sx={{justifyContent: 'space-between'}}>
                <Grid item xs={12} md={6} lg={7} sx={{mb: 5}}>
                    {data && cards?.length < 1 ? (
                        <Typography textAlign='center'>Нет данных</Typography>
                    ) : (
                        <TableScroll columns={columns} loading={loading} data={cards} load={fetchNextPage} />
                    )}
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={{pl: 2}}>
                    <Typography variant='body2' sx={{fontWeight: 'bold', py: 2}}>
                        Описание раздела
                    </Typography>
                    <Typography variant='body2'>{text}</Typography>
                </Grid>
            </Grid>
        </Container>
    );
};
