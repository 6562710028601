import axios from 'axios';
import {authControllerRefreshTokens} from 'api/authentication';
import {getTokens, ROUTES, setTokens} from 'utils';

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: null, token: string | null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

export const onRejected = (error: any) => {
    const originalRequest = error.config;
    if (originalRequest.url === '/auth/refresh') {
        window.location.href = ROUTES.SIGN_IN + `?redirectUrl=${encodeURIComponent(window.location.href)}`;
        return Promise.reject(error);
    }
    if (error.response?.status === 401 && error.response?.data?.message === 'Unauthorized' && !originalRequest._retry) {
        const {refreshToken, accessToken} = getTokens();
        if (!refreshToken || !accessToken) {
            return Promise.reject(error);
        }
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                failedQueue.push({resolve, reject});
            })
                .then((token) => {
                    originalRequest.headers['Authorization'] = 'JWT ' + token;
                    return axios(originalRequest);
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
            authControllerRefreshTokens({refreshToken})
                .then((data) => {
                    setTokens(data);
                    // axios.defaults.headers.common['Authorization'] = 'JWT ' + data.accessToken;
                    originalRequest.headers['Authorization'] = 'JWT ' + data.accessToken;
                    processQueue(null, data.accessToken);
                    resolve(axios(originalRequest));
                })
                .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                })
                .finally(() => {
                    isRefreshing = false;
                });
        });
    }

    return Promise.reject(error);
};
