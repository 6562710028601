import React, {ReactNode} from 'react';
import {Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';

export const CustomModal = (
    props: Omit<DialogProps, 'onClose'> & {
        hiddenCloseIcon?: boolean;
        customActions?: ReactNode;
        onClose?: () => void;
    }
) => {
    const {open, onClose, children, customActions, hiddenCloseIcon, ...rest} = props;
    return (
        <Dialog
            open={open}
            fullWidth
            onClose={onClose}
            maxWidth='lg'
            // sx={{
            //     ...sx,
            //     '& .MuiDialog-paperScrollPaper, .MuiDialog-paperScrollBody': fixedWidth
            //         ? {maxWidth: {xs: '100%', sm: 500, md: 800}}
            //         : ''
            // }}
            transitionDuration={{exit: 0, enter: 400, appear: 400}}
            {...rest}>
            {!hiddenCloseIcon && (
                <DialogTitle id='alert-dialog-title' typography='h6' sx={{p: 0, textAlign: 'center'}}>
                    <IconButton
                        aria-label='close'
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 12,
                            top: 12,
                            zIndex: 1000,
                            color: (theme) => theme.palette.grey[500]
                        }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            )}

            <DialogContent sx={{p: 0, '& :first-of-type': {mt: 0}}}>{children}</DialogContent>
            {customActions && <DialogActions sx={{p: 0}}>{customActions}</DialogActions>}
        </Dialog>
    );
};
