/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions, QueryFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {
    PaginatedStoryAuthorWithStoriesCount,
    StoryAuthorControllerFindAllWithStoriesCountParams,
    PaginatedStoryAuthorWithStoryLikesCount,
    StoryAuthorControllerFindAllWithStoryLikesCountParams
} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const storyAuthorControllerFindAllWithStoriesCount = (params?: StoryAuthorControllerFindAllWithStoriesCountParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedStoryAuthorWithStoriesCount>({url: `/public/story-author/ranking/stories`, method: 'get', params, signal});
};

export const getStoryAuthorControllerFindAllWithStoriesCountQueryKey = (params?: StoryAuthorControllerFindAllWithStoriesCountParams) => [
    `/public/story-author/ranking/stories`,
    ...(params ? [params] : [])
];

export type StoryAuthorControllerFindAllWithStoriesCountQueryResult = NonNullable<
    Awaited<ReturnType<typeof storyAuthorControllerFindAllWithStoriesCount>>
>;
export type StoryAuthorControllerFindAllWithStoriesCountQueryError = unknown;

export const useStoryAuthorControllerFindAllWithStoriesCount = <
    TData = Awaited<ReturnType<typeof storyAuthorControllerFindAllWithStoriesCount>>,
    TError = unknown
>(
    params?: StoryAuthorControllerFindAllWithStoriesCountParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof storyAuthorControllerFindAllWithStoriesCount>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStoryAuthorControllerFindAllWithStoriesCountQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof storyAuthorControllerFindAllWithStoriesCount>>> = ({signal}) =>
        storyAuthorControllerFindAllWithStoriesCount(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof storyAuthorControllerFindAllWithStoriesCount>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const storyAuthorControllerFindAllWithStoryLikesCount = (
    params?: StoryAuthorControllerFindAllWithStoryLikesCountParams,
    signal?: AbortSignal
) => {
    return axiosInstance<PaginatedStoryAuthorWithStoryLikesCount>({url: `/public/story-author/ranking/likes`, method: 'get', params, signal});
};

export const getStoryAuthorControllerFindAllWithStoryLikesCountQueryKey = (params?: StoryAuthorControllerFindAllWithStoryLikesCountParams) => [
    `/public/story-author/ranking/likes`,
    ...(params ? [params] : [])
];

export type StoryAuthorControllerFindAllWithStoryLikesCountQueryResult = NonNullable<
    Awaited<ReturnType<typeof storyAuthorControllerFindAllWithStoryLikesCount>>
>;
export type StoryAuthorControllerFindAllWithStoryLikesCountQueryError = unknown;

export const useStoryAuthorControllerFindAllWithStoryLikesCount = <
    TData = Awaited<ReturnType<typeof storyAuthorControllerFindAllWithStoryLikesCount>>,
    TError = unknown
>(
    params?: StoryAuthorControllerFindAllWithStoryLikesCountParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof storyAuthorControllerFindAllWithStoryLikesCount>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStoryAuthorControllerFindAllWithStoryLikesCountQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof storyAuthorControllerFindAllWithStoryLikesCount>>> = ({signal}) =>
        storyAuthorControllerFindAllWithStoryLikesCount(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof storyAuthorControllerFindAllWithStoryLikesCount>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
