import * as yup from 'yup';
import {ERROR_MESSAGE} from 'utils';

export const schema = yup.object({
    tags: yup.array().min(1, ERROR_MESSAGE.REQUIRED_EMPTY).required(ERROR_MESSAGE.REQUIRED_EMPTY),
    title: yup.string().max(150, ERROR_MESSAGE.REQUIRED_EMPTY).required(ERROR_MESSAGE.REQUIRED_EMPTY),
    description: yup
        .string()
        .max(2000, ERROR_MESSAGE.REQUIRED_EMPTY)
        .test('not-empty', 'Content is required', (value) => value !== '<p></p>')
        .required(ERROR_MESSAGE.REQUIRED_EMPTY),
    photo: yup.mixed().required(ERROR_MESSAGE.REQUIRED_EMPTY)
});
