import React from 'react';
import {Stories} from 'components/shared';
import {Container, Typography} from '@mui/material';

export const Favorite = () => {
    return (
        <Container>
            <Typography variant='h1' sx={{mt: 7.5, mb: 6}} color='secondary'>
                Избранное
            </Typography>
            <Stories />
        </Container>
    );
};
