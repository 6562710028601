import React from 'react';
import {Box} from '@mui/material';

export const HeaderTitle = ({title, icon, end}: {title: string; icon: any; end?: boolean}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                justifyContent: end ? 'flex-end' : 'flex-start'
            }}>
            <Box sx={{display: {xs: 'none', md: 'flex'}}}>{icon}</Box>
            {title}
        </Box>
    );
};
