import {ReactNode} from 'react';
import {Box, CircularProgress} from '@mui/material';

type CircularLoadingProps = {
    children: ReactNode;
    isLoading: boolean;
};

export const CircularLoading = ({children, isLoading}: CircularLoadingProps) => {
    return (
        <>
            {isLoading ? (
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box>{children}</Box>
            )}
        </>
    );
};
