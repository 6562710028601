import React, {ReactNode, useEffect} from 'react';
import {useAuthContext} from 'context';
import {getTokens, ROUTES} from 'utils';
import {useLocation} from 'react-router';

export const AccessControl = ({children}: {children: ReactNode}) => {
    const {me, isLoading} = useAuthContext();
    const {accessToken} = getTokens();
    const location = useLocation();

    useEffect(() => {
        if (!accessToken || (!isLoading && !me && window.location.pathname !== ROUTES.USER_AGREEMENT)) {
            window.location.href = ROUTES.SIGN_IN + `?redirectUrl=${encodeURIComponent(window.location.href)}`;
        }
    }, [me?.id, isLoading, location]);

    if (me || location.pathname === ROUTES.USER_AGREEMENT) {
        return <>{children}</>;
    } else return <div />;
};
