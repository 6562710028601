import React from 'react';
import {Link, Space, Text, TextLine, TextNumber, TextToRight, Title} from './components';

export const OldAgreement = () => {
    return (
        <Text pt={4}>
            <Title sx={{pt: 0}}>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</Title>
            <Title>1. ОБЩИЕ ПОЛОЖЕНИЯ</Title>

            <TextLine>
                <TextNumber>1.1.</TextNumber>
                <Text>
                    Государственное бюджетное учреждение города Москвы «Многофункциональные центры предоставления
                    государственных услуг города Москвы» (125009, г. Москва, Вознесенский пер., д. 22, ОГРН:
                    5117746050989, ИНН: 7731419456) (далее – ГБУ МФЦ города Москвы) предлагает работникам ГБУ МФЦ города
                    Москвы (далее – Пользователь) на условиях, изложенных в настоящем Пользовательском соглашении (далее
                    – Соглашение), использовать Сайт расположенный в сети «Интернет» по адресу https://mdd.mos.ru (далее
                    – Сайт), и все соответствующие веб-страницы, связанные с Сайтом.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.2.</TextNumber>
                <Text>
                    Сайт создан как среда, аккумулирующая примеры решения ситуаций, с целью продолжения, поддержания и
                    развития традиций Искреннего сервиса. Опубликованные Пользователями истории позволяют:
                    <ul>
                        <li>
                            понять ценность и значимость собственных добрых дел и разглядеть важное в ежедневной рутине,
                        </li>
                        <li>найти решения нестандартных ситуаций,</li>
                        <li>
                            вдохновлять всех Пользователей Сайта на реализацию принципов гуманности и
                            человекоориентированности.
                        </li>
                    </ul>
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.3.</TextNumber>
                <Text>
                    Под использованием Сайта и сервисов «Мои добрые дела», расположенных на Сайте (далее – Сервисы),
                    понимается в том числе:
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.3.1.</TextNumber>
                <Text>
                    Доступ к контенту Сайта, с правом просмотра и публикации материалов, включая, но не ограничиваясь
                    такими как: тексты, гипертекстовые ссылки, изображения, аудио и видеофайлы, сведения и/ или иная
                    информация, соответствующая законодательству Российской Федерации и Соглашению, в том числе Правилам
                    модерации.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.3.2.</TextNumber>
                <Text>Доступ к средствам поиска и навигации Сайта.</Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.3.3.</TextNumber>
                <Text>
                    Возможность размещения Пользователем сообщений, комментариев, рецензий, выставления оценок
                    информации (контенту) Сайта.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.4.</TextNumber>
                <Text>
                    Под действие Соглашения подпадают все существующие (функционирующие) на данный момент Сервисы, а
                    также любые их последующие модификации и появляющиеся в дальнейшем дополнительные/ новые Сервисы.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.5.</TextNumber>
                <Text>
                    Использование материалов и Сервисов, Сайта регулируется нормами действующего законодательства
                    Российской Федерации.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.6.</TextNumber>
                <Text>Исключительное право на Сайт принадлежит ГБУ МФЦ города Москвы.</Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.7.</TextNumber>
                <Text>
                    После прохождения процедуры регистрации и с момента начала использования Сервисов и/ или их
                    отдельных функций Пользователь считается принявшим условия Соглашения в полном объеме, без оговорок
                    и исключений. В случае несогласия Пользователя с какими-либо из положений Соглашения, Пользователь
                    не вправе использовать Сервис. В случае, если ГБУ МФЦ города Москвы были внесены какие-либо
                    изменения в Соглашение в порядке, предусмотренном пунктом 1.9 Соглашения, с которыми Пользователь не
                    согласен, он обязан прекратить использование Сервиса. Пользователь несет персональную
                    ответственность за проверку Соглашения на наличие изменений в нем.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.8.</TextNumber>
                <Text>Доступ к Сервисам и/ или их отдельным функциям предоставляется на безвозмездной основе.</Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.9.</TextNumber>
                <Text>
                    Соглашение может быть изменено ГБУ МФЦ города Москвы без какого-либо специального уведомления, новая
                    редакция Соглашения вступает в силу с момента ее размещения по адресу{' '}
                    <Link href='https://mdd.mos.ru'>https://mdd.mos.ru</Link>, если иное не предусмотрено новой
                    редакцией Соглашения. Действующая редакция Соглашения всегда находится на странице по адресу{' '}
                    <Link href='https://mdd.mos.ru/user-agreement'>https://mdd.mos.ru/user-agreement</Link>.
                </Text>
            </TextLine>

            <Title>2. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</Title>

            <TextLine>
                <TextNumber />
                <Text>В настоящем Соглашении используются следующие термины и понятия:</Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.1.</TextNumber>
                <Text>Сервис – комплекс услуг, предоставляемых Пользователю.</Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.2.</TextNumber>
                <Text>Содержание Сайта – текстовые, видео или фото материалы.</Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.3.</TextNumber>
                <Text>Администрация Сайта – работники ГБУ МФЦ города Москвы, уполномоченные на управление Сайтом.</Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.4.</TextNumber>
                <Text>Правила модерации Сайта – порядок использования Пользователем Сайта.</Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.5.</TextNumber>
                <Text>
                    Пользователь – работник ГБУ МФЦ города Москвы, осуществляющий доступ к Сервисам и/ или их отдельным
                    функциям.
                </Text>
            </TextLine>

            <Title>3. ИСПОЛЬЗОВАНИЕ САЙТА</Title>

            <TextLine>
                <TextNumber>3.1.</TextNumber>
                <Text>
                    Содержание Сайта не может быть скопировано, опубликовано, воспроизведено, передано или
                    распространено любым способом, а также размещено в сети «Интернет» без предварительного письменного
                    согласия Администрации сайта.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>3.2.</TextNumber>
                <Text>
                    Для использования Сайта, Сервиса и/ или некоторых отдельных функций, ГБУ МФЦ города Москвы
                    осуществляет процедуру регистрации Пользователя, в результате которой для Пользователя будет создана
                    уникальная учетная запись.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>3.3.</TextNumber>
                <Text>
                    После регистрации Пользователя с адреса электронной почты{' '}
                    <Link href='mailto:mfc-support-mdd@mfc.mos.ru'>mfc-support-mdd@mfc.mos.ru</Link> на адрес рабочей
                    электронной почты Пользователя направляются логин и пароль.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>3.4.</TextNumber>
                <Text>
                    Пользователь осознает и соглашается с тем, что фамилия, имя Пользователя может сопровождать
                    опубликованные Пользователем материалы в рамках использования Сайта, Сервиса и будет доступно для
                    других пользователей Сайта, Сервиса.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>3.5.</TextNumber>
                <Text>
                    Указанные на Сайте фамилия, имя Пользователя и иная загруженная Пользователем информация, подлежит
                    отображению для других пользователей Сервиса/ Сайта исключительно для целей предоставления Сервиса и
                    персонализации Пользователей.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>3.6.</TextNumber>
                <Text>
                    Публикуемые материалы, включая, но не ограничиваясь такими как: тексты, гипертекстовые ссылки,
                    изображения, аудио и видеофайлы, сведения и/ или иная информация принадлежит ГБУ МФЦ города Москвы
                    на правах исключительного пользования.
                </Text>
            </TextLine>

            <Title>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</Title>

            <TextLine>
                <TextNumber>4.1.</TextNumber>
                <Text>
                    <b>Администрация сайта вправе:</b>
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.1.1.</TextNumber>
                <Text>
                    Без предварительного уведомления Пользователя заблокировать или удалить учетную запись Пользователя,
                    а также запретить доступ с использованием какой-либо учетной записи к определенным Сервисам (или к
                    отдельным функциям сервиса, если это возможно технологически), и удалить любой контент без
                    объяснения причин, в случае выявления подозрительной активности Пользователя в Сервисе, а также если
                    действия Пользователя или размещаемая им информация нарушают законодательство Российской Федерации,
                    условия Соглашения, в том числе Правила модерации.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.1.2.</TextNumber>
                <Text>
                    Без предварительного уведомления Пользователя прекратить и (или) заблокировать доступ к Сайту в
                    случае прекращения действия Сайта либо по причине технической неполадки или проблемы.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.1.3.</TextNumber>
                <Text>
                    Раскрыть информацию о Пользователе, если действующее законодательство Российской Федерации требует
                    или разрешает такое раскрытие, в случае выявления подозрительной активности Пользователя в Сервисе,
                    а также если действия Пользователя или размещаемая им информация нарушают законодательство
                    Российской Федерации, условия Соглашения, в том числе Правила модерации.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.1.4.</TextNumber>
                <Text>
                    Собирать, анализировать, использовать, делиться информацией о Пользователе, содержащейся на Сайте,
                    включая, но не ограничиваясь, информацией о личных данных Пользователя, информацией о действиях
                    Пользователя на Сайте и т.д.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.1.5.</TextNumber>
                <Text>В любое время без уведомления Пользователя вносить изменения в перечень Сервисов.</Text>
            </TextLine>

            <br />
            <TextLine>
                <TextNumber>4.2.</TextNumber>
                <Text>
                    <b>Пользователь вправе:</b>
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.2.1.</TextNumber>
                <Text>Использовать Сайт, пользоваться Сервисами и/ или их отдельными функциями.</Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.2.2.</TextNumber>
                <Text>
                    Задавать вопросы, относящиеся к использованию Сайта, Сервисов и/ или их отдельных функций
                    посредством формы обратной связи, имеющейся на Сайте.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.2.3.</TextNumber>
                <Text>
                    Использовать Сайт, пользоваться Сервисами и/ или их отдельными функциями исключительно в целях и
                    порядке, предусмотренных Соглашением, в том числе Правилами модерации, и в соответствии с
                    законодательством Российской Федерации.
                </Text>
            </TextLine>

            <br />
            <TextLine>
                <TextNumber>4.3.</TextNumber>
                <Text>
                    <b>Пользователь обязуется:</b>
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.1.</TextNumber>
                <Text>
                    Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет
                    непосредственное отношение к использованию Сайта, Сервисов и/ или их отдельных функций.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.2.</TextNumber>
                <Text>
                    Соблюдать имущественные и неимущественные права авторов и иных правообладателей при использовании
                    Сайта. Пользователь гарантирует, что контент и условия его размещения соответствуют требованиям
                    применимого законодательства, Пользователем получены все необходимые для размещения контента права и
                    разрешения.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.3.</TextNumber>
                <Text>
                    Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сайта,
                    Сервисов и/ или их отдельных функций.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.4.</TextNumber>
                <Text>
                    Не распространять с использованием Сайта, Сервисов любую конфиденциальную и/ или охраняемую
                    законодательством Российской Федерации информацию о физических и/ или юридических лицах.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.5.</TextNumber>
                <Text>
                    Избегать любых действий, в результате которых может быть нарушена конфиденциальность информации,
                    охраняемой законодательством Российской Федерации.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.6.</TextNumber>
                <Text>
                    Нести ответственность за все действия, их последствия в рамках или с использованием Сервиса под
                    учетной записью Пользователя, включая случаи добровольной передачи Пользователем данных для доступа
                    к учетной записи Пользователя третьим лицам. При этом все действия в рамках или с использованием
                    Сервисов под учетной записью Пользователя считаются произведенными самим Пользователем, за
                    исключением случаев, когда Пользователь, в порядке, предусмотренном пунктом 4.3.9, уведомил
                    Администрацию сайта о несанкционированном доступе к Сервису с использованием учетной записи
                    Пользователя и/ или о любом нарушении (подозрениях о нарушении) конфиденциальности своих средств
                    доступа к учетной записи (логина и пароля).
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.7.</TextNumber>
                <Text>Не использовать Сайт для распространения информации рекламного характера.</Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.8.</TextNumber>
                <Text>
                    Не представлять себя за другого человека или представителя организации и (или) сообщества без
                    достаточных на то прав, в том числе за работников Администрации сайта.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.9.</TextNumber>
                <Text>
                    Немедленно уведомить Администрацию сайта о любом случае несанкционированного (не разрешенного
                    Пользователем) доступа к Сервису с использованием учетной записи Пользователя и/или о любом
                    нарушении (подозрениях о нарушении) конфиденциальности своих средств доступа к учетной записи.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.10.</TextNumber>
                <Text>Не использовать Сервисы с целью:</Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.10.1.</TextNumber>
                <Text>
                    Загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует
                    насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному, половому,
                    религиозному, социальному признакам; содержит недостоверные сведения и (или) оскорбления в адрес
                    конкретных лиц, организаций, органов власти.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.10.2.</TextNumber>
                <Text>
                    Побуждения к совершению противоправных действий, а также содействия лицам, действия которых
                    направлены на нарушение ограничений и запретов, действующих на территории Российской Федерации.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.10.3.</TextNumber>
                <Text>Нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме.</Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.10.4.</TextNumber>
                <Text>Ущемления прав меньшинств.</Text>
            </TextLine>

            <br />
            <TextLine>
                <TextNumber>4.4.</TextNumber>
                <Text>
                    <b>Пользователь не вправе:</b>
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.4.1.</TextNumber>
                <Text>Нарушать надлежащее функционирование Сайта, Сервисов и/ или их отдельных функций.</Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.4.2.</TextNumber>
                <Text>
                    Любым способом обходить навигационную структуру Сайта для получения или попытки получения любой
                    информации, документов или материалов любыми средствами, которые специально не представлены
                    сервисами данного Сайта.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.4.3.</TextNumber>
                <Text>
                    Осуществлять несанкционированный доступ к функциям Сайта, любым другим системам или сетям,
                    относящимся к данному Сайту, а также к любым Сервисам.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.4.4.</TextNumber>
                <Text>
                    Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся к Сайту.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.4.5.</TextNumber>
                <Text>Размещать и обрабатывать информацию ограниченного доступа на Сайте.</Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.4.6.</TextNumber>
                <Text>
                    Размещать, обрабатывать и распространять информацию, содержащую персональные данные, в том числе
                    персональные данные третьих лиц.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.4.7.</TextNumber>
                <Text>
                    Использовать Сайт в любых целях, запрещенных законодательством Российской Федерации, а также
                    подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права ГБУ МФЦ города
                    Москвы, Администрации Сайта или других лиц.
                </Text>
            </TextLine>

            <Title>5. ОТВЕТСТВЕННОСТЬ</Title>

            <TextLine>
                <TextNumber>5.1.</TextNumber>
                <Text>ГБУ МФЦ города Москвы не несет ответственности за:</Text>
            </TextLine>
            <TextLine>
                <TextNumber>5.1.1.</TextNumber>
                <Text>
                    Задержки или сбои в процессе совершения коммуникации, возникшие вследствие непреодолимой силы, а
                    также любого случая неполадок в телекоммуникационных, компьютерных, электрических и иных смежных
                    системах.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>5.1.2.</TextNumber>
                <Text>
                    Надлежащее функционирование Сайта, в случае, если Пользователь не имеет необходимых технических
                    средств для его использования, а также не несет никаких обязательств по обеспечению пользователей
                    такими средствами.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>5.1.3.</TextNumber>
                <Text>Действия Пользователя на Сайте.</Text>
            </TextLine>
            <TextLine>
                <TextNumber>5.2.</TextNumber>
                <Text>
                    Пользователь обязуется самостоятельно нести ответственность за интерпретацию, применение и
                    использование информации (контента), размещенной на Сайте.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>5.3.</TextNumber>
                <Text>
                    Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в
                    соответствии с законодательством Российской Федерации.
                </Text>
            </TextLine>

            <Title>6. НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</Title>

            <TextLine>
                <TextNumber>6.1.</TextNumber>
                <Text>
                    Администрация сайта не несет ответственности перед Пользователем или третьими лицами за прекращение
                    доступа к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения или иного
                    документа, содержащего условия пользования Сайтом.
                </Text>
            </TextLine>

            <br />
            <TextLine>
                <b>Дата публикации 28 июля 2022 г.</b>
            </TextLine>

            <Space></Space>

            <TextLine>
                <TextToRight>
                    Приложение
                    <br />к Пользовательскому соглашению
                </TextToRight>
            </TextLine>

            <Title>ПРАВИЛА МОДЕРАЦИИ</Title>

            <TextLine>
                <Text>
                    Правила модерации (далее – Правила модерации) сайта, расположенного в сети «Интернет» по адресу{' '}
                    <Link href='https://mdd.mos.ru'>https://mdd.mos.ru</Link> (далее – Сайт), и всех соответствующие
                    веб-страниц, связанные с Сайтом; сервисов «Мои добрые дела» (далее – Сервисы), расположенными на
                    Сайте, в соответствии с условиями, изложенными в Пользовательском соглашении (далее – Соглашение),
                    подготовлены в целях описания процесса модерации и публикации историй Пользователей и комментариев
                    Пользователей (далее – Тексты).
                </Text>
            </TextLine>

            <Title>1. ОБЯЗАТЕЛЬНЫЕ УСЛОВИЯ</Title>

            <TextLine>
                <TextNumber />
                <Text>Обязательными условиями для публикации Текстов на Сайте являются:</Text>
            </TextLine>
            <TextLine>
                <TextNumber>1.1.</TextNumber>
                <Text>Соответствие истории Соглашению, в том числе Правилам модерации.</Text>
            </TextLine>
            <TextLine>
                <TextNumber>1.2.</TextNumber>
                <Text>
                    Заполнение поля «Название истории» – короткий текст (до 255 символов), отражающий суть истории.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>1.3.</TextNumber>
                <Text>
                    Заполнение поля «История» – текст, который лаконично описывает определенное событие из жизни/ работы
                    Пользователя или его коллег.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>1.4.</TextNumber>
                <Text>
                    Прикрепление к публикации «Фото» (далее – Изображения) – изображения, подходящее по теме и
                    содержанию истории, в высоком качестве, в формате *.jpg или *.png и размером не более 5 Мб.
                    Изображение может быть загружено с устройства Пользователя или выбрано из имеющегося на Сайте банка
                    изображений. История может содержать не более одного Изображения.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>1.5.</TextNumber>
                <Text>
                    Выбор «Хештегов» – ключевое слово или несколько слов, облегчающих поиск историй по теме или
                    содержанию, из имеющегося на Сайте перечня.
                </Text>
            </TextLine>

            <Title>2. ОСНОВНЫЕ ПРАВИЛА</Title>

            <TextLine>
                <TextNumber>2.1.</TextNumber>
                <Text>
                    Тексты должны быть написаны на русском языке и соответствовать требованиям, предъявляемым
                    Пользовательским соглашением, в том числе Правилам модерации.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.2.</TextNumber>
                <Text>
                    Тексты должны описывать реальные ситуации, произошедшие с Пользователем и/или его коллегами.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.3.</TextNumber>
                <Text>
                    Тексты не должны содержать информации описывающей нарушение порядка предоставления государственных
                    услуг.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.4.</TextNumber>
                <Text>
                    Тексты и Изображения не должны содержать рекламную информацию или наименование брендов (такие как
                    наименование банков, магазинов, ресторанов, брендов одежды, обуви, электроники и прочее).
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.5.</TextNumber>
                <Text>
                    Тексты и Изображения не должны содержать персональные данные участников истории (такие как фамилия,
                    имя, отчество, адрес регистрации, адрес фактического проживания, дата рождения и прочее).
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.6.</TextNumber>
                <Text>
                    Тексты и Изображения не могут нарушать законодательство Российской Федерации (такие как умышленное
                    распространение ложной информации, призывы к массовым беспорядкам, осуществлению экстремистской и
                    террористической деятельности и прочее).
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.7.</TextNumber>
                <Text>
                    Все Тексты и Изображения, размещаемые на Сайте, подлежать обязательной проверке на соответствие
                    Соглашению, в том числе Правилам модерации, и публикуются на Сайте без корректировок только в случае
                    соответствия действующему Соглашению, в том числе Правилам модерации. В случае несоответствия
                    Соглашению, в том числе Правилам модерации, Тексты и Изображения могут быть скорректированы
                    модератором (таким образом, чтобы они соответствовали Соглашению, в том числе Правилам модерации, и
                    сохранили авторский стиль), или удалены с Сайта.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.8.</TextNumber>
                <Text>
                    Изображения, имеющие низкое разрешение и качество, недостаточное для различения объектов,
                    изображенных на них, подлежат корректировке (замене) на Изображения, подходящее по теме и содержанию
                    истории, на усмотрение модератора.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.9.</TextNumber>
                <Text>
                    Правила модерации вступают в силу для Пользователя с момента его авторизации на Сайте и действуют в
                    течении неопределенного срока.
                </Text>
            </TextLine>

            <br />
            <TextLine>
                <b>Дата публикации 28 июля 2022 г.</b>
            </TextLine>
            <br />
        </Text>
    );
};
