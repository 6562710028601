import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import {AppThemeProvider} from 'context';
import 'assets/styles/app.css';
import 'moment/locale/ru';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Router>
        <AppThemeProvider>
            <App />
        </AppThemeProvider>
    </Router>
);
