import React, {useMemo, useState} from 'react';
import {Badge, CircularProgress, Grid, IconButton, Paper, Popover, Typography} from '@mui/material';
import {BellIcon} from 'assets/icons';
import {getNotificationControllerGetAllQueryKey, notificationControllerGetAll} from 'api/notification';
import {NotificationControllerGetAllFilterByView, NotificationControllerGetAllParams} from 'api/mDDPublic.schemas';
import Notification from './Notification';
import {useInfiniteQuery} from '@tanstack/react-query';
import {LoadingButton} from '@mui/lab';
import {flatInfinityQuery} from 'utils';

export const Notifications = ({isNavigation}: {isNavigation?: boolean}) => {
    const query: NotificationControllerGetAllParams = {
        'filter[byView]': isNavigation ? NotificationControllerGetAllFilterByView.UNVIEWED : NotificationControllerGetAllFilterByView.ALL
    };
    const {data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, refetch} = useInfiniteQuery(
        getNotificationControllerGetAllQueryKey(query),
        ({pageParam = 0}) =>
            notificationControllerGetAll({
                ...query,
                'pagination[skip]': pageParam * (isNavigation ? 5 : 10),
                'pagination[take]': isNavigation ? 5 : 10
            }),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => (lastPage?.hasNextPage ? +lastPage?.skip / +lastPage?.take + 1 : undefined)
        }
    );
    const notifications = useMemo(() => flatInfinityQuery(data), [data]);
    const totalCount = data?.pages[0]?.totalCount;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleMenu = (event: any) => {
        refetch();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const notificationOpen = Boolean(anchorEl);
    const id = notificationOpen ? 'simple-popover' : undefined;

    const List = () => {
        return (
            <>
                {notifications && notifications?.length > 0 ? (
                    <>
                        {notifications?.map((el, index) => (
                            <Notification data={el} key={index} isNavigation={isNavigation} />
                        ))}
                        {hasNextPage && (
                            <>
                                <LoadingButton
                                    variant='outlined'
                                    color='neutral'
                                    fullWidth
                                    loading={isFetchingNextPage}
                                    onClick={() => fetchNextPage()}>
                                    Показать ещё
                                </LoadingButton>
                            </>
                        )}
                    </>
                ) : isLoading ? (
                    <CircularProgress sx={{mx: 'auto', mt: 2}} />
                ) : (
                    <>
                        {isNavigation ? (
                            <Typography
                                sx={{
                                    py: 2,
                                    textAlign: 'center',
                                    fontStyle: 'italic',
                                    color: (theme) => theme.palette.neutral.main
                                }}
                                variant='subtitle1'>
                                Уведомлений нет
                            </Typography>
                        ) : (
                            <Typography sx={{textAlign: 'center'}}>Нет данных</Typography>
                        )}
                    </>
                )}
            </>
        );
    };
    return (
        <>
            {isNavigation ? (
                <>
                    <IconButton aria-describedby={id} onClick={handleMenu} sx={{width: 40, height: 40}}>
                        <Badge badgeContent={totalCount || 0} color='error'>
                            <BellIcon />
                        </Badge>
                    </IconButton>
                    <Popover
                        sx={{
                            mt: 1.5,
                            maxHeight: 400
                        }}
                        elevation={0}
                        id={id}
                        open={notificationOpen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}>
                        <Paper
                            variant='outlined'
                            sx={{
                                backgroundColor: (theme) => theme.palette.background.secondary,
                                p: 2,
                                minWidth: notifications?.length > 0 ? 400 : 270,
                                maxWidth: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1.5
                            }}>
                            <List />
                        </Paper>
                    </Popover>
                </>
            ) : (
                <Grid container>
                    <Grid item xs={12} md={notifications && notifications.length > 0 ? 8 : 12} gap={4} display='flex' flexDirection='column'>
                        <List />
                    </Grid>
                </Grid>
            )}
        </>
    );
};
