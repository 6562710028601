/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions, QueryFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {PaginatedDistrict, DistrictControllerFindAllParams} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const districtControllerFindAll = (params?: DistrictControllerFindAllParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedDistrict>({url: `/public/district`, method: 'get', params, signal});
};

export const getDistrictControllerFindAllQueryKey = (params?: DistrictControllerFindAllParams) => [`/public/district`, ...(params ? [params] : [])];

export type DistrictControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof districtControllerFindAll>>>;
export type DistrictControllerFindAllQueryError = unknown;

export const useDistrictControllerFindAll = <TData = Awaited<ReturnType<typeof districtControllerFindAll>>, TError = unknown>(
    params?: DistrictControllerFindAllParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof districtControllerFindAll>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getDistrictControllerFindAllQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof districtControllerFindAll>>> = ({signal}) => districtControllerFindAll(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof districtControllerFindAll>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
