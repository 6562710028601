import React, {useMemo} from 'react';
import {Box, Typography} from '@mui/material';
import {TableScroll} from 'components';
import {BookIcon, CalendarColorIcon, ClockColorIcon} from 'assets/icons';
import {PublicStoryDto, UserControllerFindAllUserStoriesParams} from 'api/mDDPublic.schemas';
import {useInfiniteQuery} from '@tanstack/react-query';
import {flatInfinityQuery, formatNumber, ROUTES} from 'utils';
import moment from 'moment';
import {HeaderTitle} from '../components/HeaderTitle';
import {getUserControllerFindAllUserStoriesQueryKey, userControllerFindAllUserStories} from 'api/user';
import {useNavigate} from 'react-router-dom';

export const Drafts = () => {
    const navigate = useNavigate();

    const query: UserControllerFindAllUserStoriesParams = {
        'filter[isDraft]': 'true'
    };
    const {data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage} = useInfiniteQuery(
        getUserControllerFindAllUserStoriesQueryKey(query),
        ({pageParam = 0}) => userControllerFindAllUserStories({...query, 'pagination[skip]': pageParam * 9, 'pagination[take]': 9}),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => (lastPage?.hasNextPage ? +lastPage?.skip / +lastPage?.take + 1 : undefined)
        }
    );
    const stories = useMemo(() => flatInfinityQuery(data), [data]);
    const loading = isLoading || hasNextPage || isFetchingNextPage;

    const click = (row: PublicStoryDto) => {
        navigate(ROUTES.DRAFT + '/' + row.id);
    };
    return (
        <Box maxWidth='100%' overflow='auto'>
            {data && stories.length < 1 ? (
                <Typography textAlign='center'>Нет данных</Typography>
            ) : (
                <TableScroll columns={COLUMNS(click)} loading={loading} data={stories} load={fetchNextPage} height={312} minWidth={800} />
            )}
        </Box>
    );
};
const COLUMNS = (click: any) => [
    {
        label: <HeaderTitle title='Название' icon={<BookIcon />} />,
        dataKey: 'name',
        value: (data: PublicStoryDto) => (
            <Typography sx={{cursor: 'pointer'}} onClick={() => click(data)}>
                {data?.title || ' - '}
            </Typography>
        )
    },
    {
        label: <HeaderTitle title='Дата написания' icon={<CalendarColorIcon />} />,
        dataKey: 'status',
        value: (data: PublicStoryDto) => moment(data?.createdAt).format('DD.MM.YYYY')
    },
    {
        label: <HeaderTitle title='Срок истечения' icon={<ClockColorIcon />} />,
        dataKey: 'date',
        value: (data: PublicStoryDto) => {
            const remained = moment(data.createdAt).add(8, 'd').diff(moment(), 'days');
            return <Box color={remained <= 1 ? 'error.main' : 'inherit'}>{formatNumber(remained, ['день', 'дня', 'дней'])}</Box>;
        }
    }
];
