import React from 'react';
import {ArcElement, Chart as ChartJS, ChartOptions, Legend} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Theme, useMediaQuery} from '@mui/material';

export const DoughnutChart = ({dataChart}: any) => {
    ChartJS.register(ChartDataLabels, ArcElement, Legend);
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const data = {
        labels: ['Опубликовано', 'Не опубликовано'],
        datasets: [
            {
                data: dataChart,
                backgroundColor: ['#C39367', '#623B2A'],
                hoverBackgroundColor: ['#C39367', '#623B2A'],
                borderColor: ['#C39367', '#623B2A'],
                borderWidth: 1
            }
        ]
    };
    const options: ChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false as const,
                position: 'chartArea' as const
            },
            datalabels: {
                align: 'center' as const,
                formatter: (value: any, context: any) => {
                    const percentValue = context?.dataset?.data
                        ? Math.round(
                              (value / context?.dataset?.data?.reduce((partialSum: any, a: any) => partialSum + a, 0)) *
                                  100
                          )
                        : 0;
                    return percentValue + '%';
                },
                color: (context: any) => {
                    return context.dataIndex === 0 ? '#2C2A29' : '#FFFFFF';
                },
                font: {
                    size: isMd ? 26 : 20,
                    weight: 'bold'
                }
            }
        }
    };
    return <>{dataChart && <Doughnut data={data} options={options} />}</>;
};
