/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions, QueryFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {PaginatedDivision, DivisionControllerFindAllParams} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const divisionControllerFindAll = (params?: DivisionControllerFindAllParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedDivision>({url: `/public/division`, method: 'get', params, signal});
};

export const getDivisionControllerFindAllQueryKey = (params?: DivisionControllerFindAllParams) => [`/public/division`, ...(params ? [params] : [])];

export type DivisionControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof divisionControllerFindAll>>>;
export type DivisionControllerFindAllQueryError = unknown;

export const useDivisionControllerFindAll = <TData = Awaited<ReturnType<typeof divisionControllerFindAll>>, TError = unknown>(
    params?: DivisionControllerFindAllParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof divisionControllerFindAll>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getDivisionControllerFindAllQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof divisionControllerFindAll>>> = ({signal}) => divisionControllerFindAll(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof divisionControllerFindAll>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
