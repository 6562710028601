import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Card, Container, Grid, IconButton, Stack, Typography} from '@mui/material';
import {SubmitHandler, useForm} from 'react-hook-form';
import {Form, FormTextField, RichTextInput} from 'components/form';
import {useModal} from 'hooks';
import {TagsSearch} from './TagsSearch';
import {useStoryControllerCreate, useStoryControllerFindOne, useStoryControllerUpdateDraft} from 'api/story';
import {useNavigate, useParams} from 'react-router-dom';
import {HelpIcon} from 'assets/icons';
import {Labeled} from 'components/form/Labeled';
import {CustomModal} from 'components';
import RulesImg from 'assets/images/rules.jpg';
import {LoadingButton} from '@mui/lab';
import {ValidationError} from 'yup';
import {formatNumber, ROUTES} from 'utils';
import {CreateStoryPublicDtoIsDraft, Tag} from 'api/mDDPublic.schemas';
import {useQueryClient} from '@tanstack/react-query';
import {getUserControllerFindAllUserStoriesQueryKey} from 'api/user';
import moment from 'moment';
import {Editor} from '@tiptap/react';
import {AddPhotoButton} from './AddPhotoButton';
import {schema} from './schema';

type FormData = {
    tags: Tag[];
    title: string;
    description: string;
    photo: any;
};

export const CreateStory = () => {
    const editorRef = useRef<{editor: Editor}>();
    const queryClient = useQueryClient();

    const {storyId: storyIdParam} = useParams();
    const [storyId, setStoryId] = useState(storyIdParam);
    const form = useForm<FormData>();
    const {handleSubmit, clearErrors, setError, reset, getValues} = form;

    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const {open: openRulesModal, handleToggle: handleToggleRulesModal} = useModal();
    const {open: openSuccessModal, handleToggle: handleToggleSuccessModal} = useModal();
    const [text, setText] = useState('');
    const navigate = useNavigate();

    const createStoryMutation = useStoryControllerCreate();
    const createStoryAutoSaveMutation = useStoryControllerCreate();
    const updateDraftMutation = useStoryControllerUpdateDraft();
    const updateDraftAutoSaveMutation = useStoryControllerUpdateDraft();
    const {data: story} = useStoryControllerFindOne(storyId!, {query: {enabled: !!storyId}});

    useEffect(() => {
        if (story) {
            editorRef?.current?.editor?.commands.setContent(story.description);
            reset({
                tags: story.tags.map((i) => i.tag),
                title: story.title,
                description: story.description,
                photo: story.file ? {file: story.file} : undefined
            });
        }
    }, [story]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (!storyId) {
            createStoryAutoSaveMutation
                .mutateAsync({
                    data: getData(getValues(), true)
                })
                .then((res) => setStoryId(res.id.toString()));
        } else {
            interval = setInterval(() => {
                updateDraftAutoSaveMutation.mutateAsync({
                    id: storyId!,
                    data: getData(getValues(), true)
                });
            }, 10000);
        }

        return () => clearInterval(interval);
    }, [storyId]);

    const getData = (data: FormData, isDraft: boolean) => ({
        title: data?.title,
        description: editorRef?.current?.editor?.getHTML() as string,
        fileId: data.photo?.file?.id,
        uploadedFile: !data.photo?.file?.url ? data.photo : undefined,
        tagIds: data?.tags?.map((el: Tag) => el.id.toString()),
        isDraft: isDraft.toString() as CreateStoryPublicDtoIsDraft
    });

    const createStory = (data: FormData, isDraft: boolean) => {
        handleToggleSuccessModal();
        timeoutRef.current = setTimeout(() => {
            const query = storyId ? updateDraftMutation : createStoryMutation;
            query
                .mutateAsync({
                    id: storyId!,
                    data: getData(data, isDraft)
                })
                .then(() => {
                    handleToggleSuccessModal();
                    queryClient.refetchQueries(getUserControllerFindAllUserStoriesQueryKey()).then(() => navigate(ROUTES.PROFILE));
                });
        }, 2000);
    };

    const onStorySubmit: SubmitHandler<FormData> = (data) => {
        schema
            .validate(data, {abortEarly: false})
            .then(() => {
                createStory(data, false);
                setText('Ваша история отправлена на модерацию.');
            })
            .catch((err) =>
                err.inner.forEach((e: ValidationError) =>
                    setError(e.path as keyof FormData, {type: e.type, message: e.message})
                )
            );
    };

    const onDraftSubmit: SubmitHandler<FormData> = (data) => {
        const remained = moment(story?.createdAt).add(8, 'd').diff(moment(), 'days');
        setText(
            `История будет храниться в черновиках ${
                story?.createdAt ? 'ещё ' + formatNumber(remained, ['день', 'дня', 'дней']) : '7 дней'
            }.`
        );
        createStory(data, true);
    };

    return (
        <>
            <Container>
                <Box sx={{display: 'flex', mt: 7.5, mb: 6, flexWrap: 'wrap'}}>
                    <Typography variant='h1' color='secondary'>
                        Доброе&nbsp;
                    </Typography>
                    <Typography variant='h1' color='primary'>
                        Дело
                    </Typography>
                </Box>
                <Form form={form}>
                    <Card
                        sx={{
                            overflow: 'visible',
                            padding: 3.75
                        }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={4} sx={{display: 'flex', flexDirection: 'column'}}>
                                <AddPhotoButton />

                                <TagsSearch />
                            </Grid>
                            <Grid item xs={12} lg={8} sx={{gap: {xs: 3, md: 6}, display: 'flex', flexDirection: 'column'}}>
                                <Labeled
                                    label='Название истории'
                                    tooltip={
                                        <IconButton onClick={handleToggleRulesModal} sx={{my: '-13px'}}>
                                            <HelpIcon />
                                        </IconButton>
                                    }>
                                    <FormTextField name='title' placeholder={' '} maxLength={150} />
                                </Labeled>
                                <RichTextInput ref={editorRef} name='description' />
                            </Grid>
                        </Grid>
                        <Stack direction={{md: 'row'}} gap={{xs: 2, md: 3}} sx={{justifyContent: 'flex-end', mt: 3}}>
                            <Button
                                variant='contained'
                                color='neutral'
                                onClick={() => navigate(-1)}
                                sx={{
                                    height: 'auto',
                                    p: '14px 80px'
                                }}>
                                Отменить
                            </Button>
                            <LoadingButton
                                loading={updateDraftMutation.isLoading}
                                type='submit'
                                variant='contained'
                                color='neutral'
                                onClick={() => {
                                    clearErrors();
                                    handleSubmit(onDraftSubmit)();
                                }}
                                sx={{
                                    height: 'auto',
                                    p: '14px 80px'
                                }}>
                                В черновик
                            </LoadingButton>
                            <LoadingButton
                                loading={createStoryMutation.isLoading}
                                type='submit'
                                variant='contained'
                                onClick={() => {
                                    clearErrors();
                                    handleSubmit(onStorySubmit)();
                                }}
                                sx={{p: '14px 80px'}}>
                                Отправить
                            </LoadingButton>
                        </Stack>
                    </Card>
                </Form>
            </Container>

            <CustomModal maxWidth='sm' scroll='body' open={openRulesModal} onClose={handleToggleRulesModal}>
                <Box component='img' sx={{width: '100%', p: 2}} src={RulesImg} />
            </CustomModal>
            <CustomModal
                open={openSuccessModal}
                onClose={handleToggleSuccessModal}
                hiddenCloseIcon
                sx={{'.MuiDialogContent-root': {p: 2}, '*': {color: '#2c2a29'}}}
                fullWidth={false}>
                {text}
                <Button
                    color='neutral'
                    sx={{py: 1, ml: 2}}
                    onClick={() => {
                        clearTimeout(timeoutRef.current!);
                        handleToggleSuccessModal();
                    }}>
                    Отменить
                </Button>
            </CustomModal>
        </>
    );
};
