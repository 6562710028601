import {useState} from 'react';

export const useScrollVisible = (height = 180) => {
    const [visible, setVisible] = useState(false);
    onscroll = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > height) {
            setVisible(true);
        } else if (scrolled <= height) {
            setVisible(false);
        }
    };
    return visible;
};
