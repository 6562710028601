import React from 'react';
import {Link, Space, Text, TextLine, TextNumber, TextToRight, Title} from './components';

export const NewAgreement = () => {
    return (
        <Text pt={4}>
            <Title sx={{pt: 0}}>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</Title>
            <Text>
                Настоящее Пользовательское соглашение (далее – Соглашение) определяет условия, на которых
                Государственное бюджетное учреждение города Москвы «Многофункциональные центры предоставления
                государственных услуг города Москвы» (125009, г. Москва, Вознесенский пер., д. 22, ОГРН: 5117746050989,
                ИНН: 7731419456) (далее – ГБУ МФЦ города Москвы) предлагает Пользователю использовать корпоративную
                социальную сеть, расположенную в сети «Интернет» по адресу:{' '}
                <Link href='https://mdd.mos.ru'>https://mdd.mos.ru</Link> (далее – Сайт).
            </Text>

            <Title>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</Title>

            <TextLine>
                <TextNumber>1.1.</TextNumber>
                <Text>Владелец сайта – ГБУ МФЦ города Москвы.</Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.2.</TextNumber>
                <Text>
                    Пользователь – физическое лицо, состоящее в трудовых отношениях с ГБУ МФЦ города Москвы, действующее
                    в собственном интересе для публикации историй о своих достижениях, а также примеров решения ситуаций
                    с целью продолжения, поддержания и развития традиций Искреннего сервиса, осуществляющее доступ к
                    Сайту.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.3.</TextNumber>
                <Text>
                    Искренний сервис – культура, в основе которой лежит умение смотреть на ситуацию с позиции клиента и
                    решать задачи с точки зрения его интересов, одновременно находя собственную мотивацию.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.4.</TextNumber>
                <Text>
                    Пример искреннего сервиса – пример решения ситуации и (или) помощи заявителю, связанный с работой
                    ГБУ МФЦ города Москвы, и на который отсутствовало прямое указание в действующих инструкциях ГБУ МФЦ
                    города Москвы.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.5.</TextNumber>
                <Text>Администрация сайта – работники ГБУ МФЦ города Москвы, осуществляющие управление Сайтом.</Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.6.</TextNumber>
                <Text>
                    Контент сайта – любая информация и материалы, публикуемые на Сайте, в том числе Контент
                    пользователя.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.7.</TextNumber>
                <Text>
                    Контент пользователя – любая информация и материалы, направленные Пользователем для публикации, а
                    также сообщения, комментарии, рецензии, изображения, публикуемые Пользователем на Сайте.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.8.</TextNumber>
                <Text>Правила модерации сайта – условия, которым должен соответствовать Контент сайта.</Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.9.</TextNumber>
                <Text>Использование сайта:</Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.9.1.</TextNumber>
                <Text>
                    Доступ к Контенту сайта с правом просмотра и публикации материалов, включая, но не ограничиваясь
                    такими как: тексты, изображения, аудио и видеофайлы, сведения и/ или иная информация,
                    соответствующая законодательству Российской Федерации, Соглашению, в том числе Правилам модерации
                    сайта.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.9.2.</TextNumber>
                <Text>Доступ к средствам поиска и навигации Сайта.</Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.9.3</TextNumber>
                <Text>
                    Возможность размещения Пользователем сообщений, комментариев, рецензий, изображений, выставления
                    оценок Контенту сайта.
                </Text>
            </TextLine>

            <Title>2. ОБЩИЕ ПОЛОЖЕНИЯ</Title>

            <TextLine>
                <TextNumber>2.1.</TextNumber>
                <Text>
                    Сайт создан как{' '}
                    <b>
                        среда, аккумулирующая примеры искреннего сервиса Пользователей, решения ситуаций с целью
                        продолжения, поддержания и развития традиций Искреннего сервиса, которые позволяют:
                    </b>
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.1.1.</TextNumber>
                <Text>
                    <b>Понять ценность и значимость собственных поступков и разглядеть важное в ежедневной рутине.</b>
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.1.2.</TextNumber>
                <Text>
                    <b>Находить решения нестандартных ситуаций.</b>
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.1.3.</TextNumber>
                <Text>
                    <b>
                        Вдохновлять всех Пользователей Сайта на реализацию принципов гуманности и
                        человекоориентированности.
                    </b>
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>2.2.</TextNumber>
                <Text>
                    Пользователь считается принявшим условия Соглашения в полном объеме, без оговорок и исключений с
                    момента начала использования Сайта путем активации логина и пароля. В случае несогласия Пользователя
                    с любыми условиями Соглашения, Пользователь не вправе использовать Сайт. В случае, если Владельцем
                    сайта были внесены какие-либо изменения в Соглашение, с которыми Пользователь не согласен, он обязан
                    прекратить использование Сайта.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>2.3.</TextNumber>
                <Text>Доступ к Сайту предоставляется на безвозмездной основе.</Text>
            </TextLine>

            <TextLine>
                <TextNumber>2.3.</TextNumber>
                <Text>
                    Соглашение может быть изменено Владельцем сайта без какого-либо специального уведомления, новая
                    редакция Соглашения вступает в силу с момента ее размещения по адресу{' '}
                    <Link href='https://mdd.mos.ru'>https://mdd.mos.ru</Link>, если иное не предусмотрено новой
                    редакцией Соглашения. Действующая редакция Соглашения всегда находится на веб-странице по адресу{' '}
                    <Link href='https://mdd.mos.ru/user-agreement'>https://mdd.mos.ru/user-agreement</Link>.
                    Пользователь несет персональную ответственность за проверку Соглашения на наличие изменений в нем.
                </Text>
            </TextLine>

            <Title>3. ИСПОЛЬЗОВАНИЕ САЙТА</Title>

            <TextLine>
                <TextNumber>3.1.</TextNumber>
                <Text>
                    В целях использования сайта Пользователю на адрес рабочей электронной почты направляется
                    автоматически сгенерированный логин и пароль.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>3.2.</TextNumber>
                <Text>
                    Пользователь осознает и соглашается с тем, что его фамилия и имя могут сопровождать опубликованные
                    материалы в рамках использования Сайта и будут доступны для других Пользователей Сайта.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>3.3.</TextNumber>
                <Text>
                    Указанные на Сайте фамилия и имя Пользователя и иная загруженная Пользователем информация подлежат
                    отображению для других Пользователей Сайта исключительно для целей использования Сайта и
                    персонализации Пользователей.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>3.4.</TextNumber>
                <Text>
                    Обработка предоставляемых Пользователем персональных данных осуществляется Администрацией сайта в
                    соответствии с Федеральным законом от 27 июля 2006 г. № 152-ФЗ «О персональных данных».
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>3.5.</TextNumber>
                <Text>
                    Пользователь предоставляет по запросу Администрации сайта дополнительную информацию, которая имеет
                    непосредственное отношение к использованию Сайта.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>3.6.</TextNumber>
                <Text>
                    Владельцу сайта принадлежит право использования результатов интеллектуальной деятельности,
                    размещенных на Сайте, в том числе элементов дизайна, текстов, графических изображений, иллюстраций,
                    видео, музыки, звуков и других объектов, за исключением результатов интеллектуальной деятельности,
                    указанных в п.3.8 Соглашения.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.7.</TextNumber>
                <Text>
                    Контент сайта не может быть скопирован, опубликован, воспроизведен, передан или распространен любым
                    способом, а также размещен в сети «Интернет» без предварительного письменного согласия Администрации
                    сайта.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.8.</TextNumber>
                <Text>
                    Пользователю принадлежит исключительное право на материалы, в том числе текст, изображения,
                    графические объекты, звуки, аудио, музыку, видео и другие объекты, направленные им Администрации
                    сайта с целью их публикации на Сайте.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.9.</TextNumber>
                <Text>
                    Пользователь безвозмездно предоставляет Администрации сайта права на обработку предоставленных
                    материалов без искажения смысловой части с правом на последующее использование, воспроизведение,
                    распространение, создание производных произведений, а также на демонстрацию материалов и доведение
                    их до всеобщего сведения.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.10.</TextNumber>
                <Text>При использовании Сайта недопустимо совершать следующие действия:</Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.10.1.</TextNumber>
                <Text>
                    Представлять себя за другого человека или представителя организации и (или) сообщества без
                    достаточных на то прав, в том числе за работников Администрации сайта.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.10.2.</TextNumber>
                <Text>
                    Обходить навигационную структуру Сайта для получения или попытки получения любой информации,
                    документов или материалов любыми средствами, которые специально не представлены на Сайте.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.10.3.</TextNumber>
                <Text>
                    Осуществлять несанкционированный доступ к функциям Сайта, любым другим системам или сетям,
                    относящимся к данному Сайту.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.10.4.</TextNumber>
                <Text>Нарушать интеллектуальные права третьих лиц.</Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.10.5.</TextNumber>
                <Text>Использовать Сайт в целях:</Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.10.5.1.</TextNumber>
                <Text>
                    Загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует
                    насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному, половому,
                    религиозному, социальному признакам; содержит недостоверные сведения и (или) оскорбления в адрес
                    конкретных лиц, организаций, органов власти.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.10.5.2.</TextNumber>
                <Text>
                    Побуждения к совершению противоправных действий, а также содействия лицам, действия которых
                    направлены на нарушение ограничений и запретов, действующих на территории Российской Федерации.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.10.5.3.</TextNumber>
                <Text>Нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме.</Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.10.5.4.</TextNumber>
                <Text>
                    Подстрекательства к любой незаконной деятельности или другой деятельности, нарушающей права
                    Владельца сайта, Администрации Сайта или третьих лиц.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.10.5.5.</TextNumber>
                <Text>Распространения информации рекламного характера.</Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.10.5.6.</TextNumber>
                <Text>
                    Распространения любой конфиденциальной и (или) охраняемой законодательством Российской Федерации
                    информации о физических и (или) юридических лицах.
                </Text>
            </TextLine>

            <Title>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</Title>

            <TextLine>
                <TextNumber>4.1.</TextNumber>
                <Text>
                    <b>Администрация сайта вправе:</b>
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.1.1.</TextNumber>
                <Text>
                    Без предварительного уведомления Пользователя заблокировать или удалить учетную запись Пользователя,
                    запретить доступ к Сайту, удалить любые материалы без объяснения причин в случае выявления
                    подозрительной активности Пользователя на Сайте, а также если действия Пользователя или размещаемая
                    им информация нарушают законодательство Российской Федерации, условия Соглашения, в том числе
                    Правила модерации.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.1.2.</TextNumber>
                <Text>
                    Без предварительного уведомления Пользователя прекратить и (или) заблокировать доступ к Сайту в
                    случае прекращения действия Сайта либо по техническим причинам.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.1.3.</TextNumber>
                <Text>
                    Собирать, анализировать и осуществлять публикацию информации о достижениях Пользователей в целях
                    мотивации на проявление инициативы и поддержание традиций Искреннего сервиса, а также использовать
                    информацию, предоставленную самим Пользователем, в том числе, но не ограничиваясь информацией о
                    персональных данных Пользователя, его месте работы, занимаемой должности, а также о действиях
                    Пользователя в качестве показательного примера решения ситуаций в традициях Искреннего сервиса, и
                    делиться такой информацией на Сайте.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.1.4.</TextNumber>
                <Text>
                    Направлять Пользователю сообщения, содержащие организационно-техническую информацию о
                    функционировании Сайта и иную информацию, непосредственно связанную с исполнением условий настоящего
                    Соглашения.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.1.5.</TextNumber>
                <Text>
                    Производить профилактические работы на Сайте с временным приостановлением его функционирования.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>4.1.6.</TextNumber>
                <Text>Вносить изменения в структуру и дизайн Сайта.</Text>
            </TextLine>

            <br />
            <TextLine>
                <TextNumber>4.2.</TextNumber>
                <Text>
                    <b>Администрация сайта обязана:</b>
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.2.1.</TextNumber>
                <Text>
                    Обеспечить доступ Пользователя к Сайту после прохождения Пользователем процедуры активации логина и
                    пароля.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.2.2.</TextNumber>
                <Text>
                    Осуществлять администрирование Сайта, в том числе поддержание Сайта в работоспособном состоянии и
                    обеспечение его доступности.
                </Text>
            </TextLine>

            <br />
            <TextLine>
                <TextNumber>4.3.</TextNumber>
                <Text>
                    <b>Пользователь вправе:</b>
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.1.</TextNumber>
                <Text>
                    Использовать Сайт и задавать вопросы, относящиеся к использованию Сайта посредством формы обратной
                    связи, имеющейся на Сайте.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.3.2.</TextNumber>
                <Text>
                    Направлять предложения Администрации сайта по улучшению работы Сайта, размещать комментарии,
                    рецензии, изображения, выставлять оценки Контенту сайта.
                </Text>
            </TextLine>

            <br />
            <TextLine>
                <TextNumber>4.4.</TextNumber>
                <Text>
                    <b>Пользователь обязан:</b>
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.4.1.</TextNumber>
                <Text>
                    Соблюдать имущественные и неимущественные права авторов и иных правообладателей при использовании
                    Сайта. Пользователь гарантирует, что Контент пользователя соответствуют требованиям законодательства
                    Российской Федерации.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.4.2.</TextNumber>
                <Text>
                    Нести ответственность за все действия, их последствия в рамках или с использованием Сайта под
                    учетной записью Пользователя, включая случаи добровольной передачи Пользователем данных для доступа
                    к учетной записи Пользователя третьим лицам. При этом все действия в рамках или с использованием
                    Сайта под учетной записью Пользователя считаются произведенными самим Пользователем, за исключением
                    случаев, когда Пользователь, в порядке, предусмотренном пунктом 4.4.3 Соглашения уведомил
                    Администрацию сайта о несанкционированном доступе к Сайту.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>4.4.3.</TextNumber>
                <Text>
                    Немедленно уведомить Администрацию сайта о любом случае несанкционированного (не разрешенного
                    Пользователем) доступа к Сайту с использованием учетной записи Пользователя и/или о любом нарушении
                    (подозрениях о нарушении) конфиденциальности своих средств доступа к учетной записи (логина и
                    пароля).
                </Text>
            </TextLine>

            <Title>5. ОТВЕТСТВЕННОСТЬ</Title>

            <TextLine>
                <TextNumber>5.1.</TextNumber>
                <Text>Владелец сайта не несет ответственности перед Пользователем или третьими лицами за: </Text>
            </TextLine>

            <TextLine>
                <TextNumber>5.1.1.</TextNumber>
                <Text>
                    Прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения
                    или иного документа, содержащего условия пользования Сайтом.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>5.1.2.</TextNumber>
                <Text>
                    Задержки, сбои в процессе совершения коммуникации, любые случаи неполадок в телекоммуникационных,
                    компьютерных, электрических и иных смежных системах.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>5.1.3.</TextNumber>
                <Text>
                    Надлежащее функционирование Сайта, в случае, если Пользователь не имеет необходимых технических
                    средств для его использования.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>5.1.4.</TextNumber>
                <Text>Нарушение Пользователем интеллектуальных прав третьих лиц.</Text>
            </TextLine>

            <TextLine>
                <TextNumber>5.2.</TextNumber>
                <Text>
                    Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в
                    соответствии с законодательством Российской Федерации.
                </Text>
            </TextLine>

            <Title>6. ПРОЧИЕ УСЛОВИЯ</Title>

            <TextLine>
                <TextNumber>6.1.</TextNumber>
                <Text>
                    Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской
                    Федерации. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с
                    законодательством Российской Федерации.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>6.2.</TextNumber>
                <Text>
                    Условия настоящего Соглашения действуют до тех пор, пока одна из сторон не заявит о своем намерении
                    выйти из Соглашения.
                </Text>
            </TextLine>

            <br />
            <TextLine>
                <b>Дата публикации 07 июля 2023 г.</b>
            </TextLine>

            <Space></Space>

            <TextLine>
                <TextToRight>
                    Приложение
                    <br />к Пользовательскому соглашению
                </TextToRight>
            </TextLine>

            <Title>ПРАВИЛА МОДЕРАЦИИ</Title>

            <TextLine>
                <Text>
                    Правила модерации (далее – Правила модерации) сайта, расположенного в сети «Интернет» по адресу{' '}
                    <Link href='https://mdd.mos.ru'>https://mdd.mos.ru</Link> (далее – Сайт) определяют следующие
                    условия, которым должен соответствовать Контент сайта:
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>1.</TextNumber>
                <Text>Контент сайта должен соответствовать условиям Пользовательского соглашения.</Text>
            </TextLine>
            <TextLine>
                <TextNumber>2.</TextNumber>
                <Text>
                    Текст истории должен описывать реальные ситуации, совершенные Пользователем и/ или его коллегами и
                    не должны содержать информации, описывающей нарушение порядка предоставления государственных услуг.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>3.</TextNumber>
                <Text>Требования к заполнению полей Сайта:</Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.1.</TextNumber>
                <Text>
                    Поле «Название истории» должно содержать короткий текст (до 150 символов), отражающий суть истории.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.2.</TextNumber>
                <Text>
                    Поле «История» должно содержать текст, который лаконично описывает пример искреннего сервиса
                    Пользователя или его коллег и не превышать 2000 символов.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.3.</TextNumber>
                <Text>
                    Прикрепляемое к публикации «Фото» - это изображение, подходящее по теме и содержанию истории, в
                    высоком качестве, в формате *.jpg или *.png и размером не более 5 Мб (далее – Изображение).
                    Изображение может быть загружено с устройства Пользователя или выбрано из имеющегося на Сайте банка
                    изображений. История может содержать не более одного Изображения.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>3.4.</TextNumber>
                <Text>
                    Выбор «Хештегов» - это указание ключевого слова или нескольких слов, облегчающих поиск историй по
                    теме или содержанию, из имеющегося на Сайте перечня.
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>4.</TextNumber>
                <Text>
                    Тексты и Изображения не должны содержать рекламную информацию или наименование брендов (наименование
                    банков, магазинов, ресторанов, брендов одежды, обуви, электроники и прочее).
                </Text>
            </TextLine>

            <TextLine>
                <TextNumber>5.</TextNumber>
                <Text>
                    Тексты и Изображения не должны содержать персональные данные участников истории (фамилия, имя,
                    отчество, адрес регистрации, адрес фактического проживания, дата рождения и прочее).
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>6.</TextNumber>
                <Text>
                    Тексты и Изображения, направленные Пользователем для размещения на Сайте, подлежат обязательной
                    проверке на соответствие Соглашению, и публикуются на Сайте без корректировок только в случае
                    соответствия действующему Соглашению. В случае несоответствия Соглашению, тексты и Изображения могут
                    быть скорректированы Администрацией сайта или удалены с Сайта.
                </Text>
            </TextLine>
            <TextLine>
                <TextNumber>7.</TextNumber>
                <Text>
                    Изображения, имеющие низкое разрешение и качество, недостаточное для различения объектов,
                    изображенных на них, подлежат корректировке (замене) на Изображения, подходящие по теме и содержанию
                    истории, на усмотрение Администрации сайта .
                </Text>
            </TextLine>
            <br />
            <TextLine>
                <b>Дата публикации 07 июля 2023 г.</b>
            </TextLine>
            <br />
        </Text>
    );
};
