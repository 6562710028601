/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions, QueryFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {PaginatedTag, TagControllerFindAllParams} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const tagControllerFindAll = (params?: TagControllerFindAllParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedTag>({url: `/public/tag`, method: 'get', params, signal});
};

export const getTagControllerFindAllQueryKey = (params?: TagControllerFindAllParams) => [`/public/tag`, ...(params ? [params] : [])];

export type TagControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof tagControllerFindAll>>>;
export type TagControllerFindAllQueryError = unknown;

export const useTagControllerFindAll = <TData = Awaited<ReturnType<typeof tagControllerFindAll>>, TError = unknown>(
    params?: TagControllerFindAllParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof tagControllerFindAll>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getTagControllerFindAllQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tagControllerFindAll>>> = ({signal}) => tagControllerFindAll(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof tagControllerFindAll>>, TError, TData>({queryKey, queryFn, ...queryOptions}) as UseQueryResult<
        TData,
        TError
    > & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
