import {createContext, ReactNode, useContext, useState} from 'react';
import {MODE} from 'theme';

export type ThemeContextType = {
    theme: string;
    saveTheme: (value: string) => void;
};
export const ThemeContext = createContext({} as ThemeContextType);

export const AppThemeProvider = ({children}: {children: ReactNode}) => {
    const [theme, setTheme] = useState(
        localStorage.getItem('theme') ||
            (window.matchMedia('(prefers-color-scheme: DARK)').matches ? MODE.DARK : MODE.LIGHT)
    );

    const saveTheme = (value: string) => {
        setTheme(value);
        window.localStorage.setItem('theme', value);
    };
    return <ThemeContext.Provider value={{theme, saveTheme}}>{children}</ThemeContext.Provider>;
};

export const useThemeContext = () => useContext(ThemeContext);
