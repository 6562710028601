import React from 'react';
import {HeaderSearch} from 'components/shared/HeaderSearch';
import {Categories} from './Categories';
import {Container} from '@mui/material';
import {Statistics} from './Statistics';

export const Home = () => {
    return (
        <>
            <HeaderSearch />
            <Container>
                <Categories />
                <Statistics />
            </Container>
        </>
    );
};
