/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions, QueryFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {PaginatedMedia, MediaControllerFindAllParams} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const mediaControllerFindAll = (params?: MediaControllerFindAllParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedMedia>({url: `/public/media`, method: 'get', params, signal});
};

export const getMediaControllerFindAllQueryKey = (params?: MediaControllerFindAllParams) => [`/public/media`, ...(params ? [params] : [])];

export type MediaControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof mediaControllerFindAll>>>;
export type MediaControllerFindAllQueryError = unknown;

export const useMediaControllerFindAll = <TData = Awaited<ReturnType<typeof mediaControllerFindAll>>, TError = unknown>(
    params?: MediaControllerFindAllParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof mediaControllerFindAll>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getMediaControllerFindAllQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof mediaControllerFindAll>>> = ({signal}) => mediaControllerFindAll(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof mediaControllerFindAll>>, TError, TData>({queryKey, queryFn, ...queryOptions}) as UseQueryResult<
        TData,
        TError
    > & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
