import React, {useMemo} from 'react';
import {Box, Typography} from '@mui/material';
import {TableScroll} from 'components';
import {BookIcon, CalendarColorIcon, StatusColorIcon} from 'assets/icons';
import {PublicStoryDto, UserControllerFindAllUserStoriesParams} from 'api/mDDPublic.schemas';
import {useInfiniteQuery} from '@tanstack/react-query';
import {flatInfinityQuery} from 'utils';
import moment from 'moment';
import {HeaderTitle} from '../components/HeaderTitle';
import {getUserControllerFindAllUserStoriesQueryKey, userControllerFindAllUserStories} from 'api/user';
import {useLocation, useNavigate} from 'react-router-dom';
import {getStatus} from 'pages/Profile/Statistics';

export const AllStories = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const query: UserControllerFindAllUserStoriesParams = {
        'filter[isDraft]': 'false'
    };
    const {data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage} = useInfiniteQuery(
        getUserControllerFindAllUserStoriesQueryKey(query),
        ({pageParam = 0}) => userControllerFindAllUserStories({...query, 'pagination[skip]': pageParam * 9, 'pagination[take]': 9}),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => (lastPage?.hasNextPage ? +lastPage?.skip / +lastPage?.take + 1 : undefined)
        }
    );
    const stories = useMemo(() => flatInfinityQuery(data), [data]);
    const loading = isLoading || hasNextPage || isFetchingNextPage;
    const click = (row: PublicStoryDto) => {
        navigate(location.pathname + '?storyId=' + row.id);
    };
    return (
        <Box maxWidth='100%' overflow='auto'>
            {data && stories.length < 1 ? (
                <Typography textAlign='center'>Нет данных</Typography>
            ) : (
                <TableScroll columns={COLUMNS(click)} loading={loading} data={stories} load={fetchNextPage} height={312} minWidth={800} />
            )}
        </Box>
    );
};
const COLUMNS = (click: any) => [
    {
        label: <HeaderTitle title='Название' icon={<BookIcon />} />,
        dataKey: 'name',
        value: (data: PublicStoryDto) => (
            <Typography sx={{cursor: 'pointer'}} onClick={() => click(data)}>
                {data?.title}
            </Typography>
        )
    },
    {
        label: <HeaderTitle title='Статус' icon={<StatusColorIcon />} />,
        dataKey: 'status',
        value: (data: PublicStoryDto) => <Box color={getStatus(data)?.color}>{getStatus(data)?.title}</Box>
    },
    {
        label: <HeaderTitle title='Дата написания' icon={<CalendarColorIcon />} />,
        dataKey: 'date',
        value: (data: PublicStoryDto) => moment(data?.createdAt).format('DD.MM.YYYY')
    }
];
