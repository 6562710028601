/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery, useMutation} from '@tanstack/react-query';
import type {UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {Story, ViewNotificationInput, PaginatedNotification, NotificationControllerGetAllParams} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const notificationControllerViewNotification = (viewNotificationInput: ViewNotificationInput) => {
    return axiosInstance<Story>({
        url: `/public/notification`,
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        data: viewNotificationInput
    });
};

export type NotificationControllerViewNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof notificationControllerViewNotification>>>;
export type NotificationControllerViewNotificationMutationBody = ViewNotificationInput;
export type NotificationControllerViewNotificationMutationError = unknown;

export const useNotificationControllerViewNotification = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof notificationControllerViewNotification>>,
        TError,
        {data: ViewNotificationInput},
        TContext
    >;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof notificationControllerViewNotification>>, {data: ViewNotificationInput}> = (
        props
    ) => {
        const {data} = props ?? {};

        return notificationControllerViewNotification(data);
    };

    return useMutation<Awaited<ReturnType<typeof notificationControllerViewNotification>>, TError, {data: ViewNotificationInput}, TContext>(
        mutationFn,
        mutationOptions
    );
};
export const notificationControllerGetAll = (params: NotificationControllerGetAllParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedNotification>({url: `/public/notification`, method: 'get', params, signal});
};

export const getNotificationControllerGetAllQueryKey = (params: NotificationControllerGetAllParams) => [
    `/public/notification`,
    ...(params ? [params] : [])
];

export type NotificationControllerGetAllQueryResult = NonNullable<Awaited<ReturnType<typeof notificationControllerGetAll>>>;
export type NotificationControllerGetAllQueryError = unknown;

export const useNotificationControllerGetAll = <TData = Awaited<ReturnType<typeof notificationControllerGetAll>>, TError = unknown>(
    params: NotificationControllerGetAllParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof notificationControllerGetAll>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getNotificationControllerGetAllQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationControllerGetAll>>> = ({signal}) =>
        notificationControllerGetAll(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof notificationControllerGetAll>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
