import {Box, Stack, Typography} from '@mui/material';
import React, {PropsWithChildren, ReactNode} from 'react';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material/styles';

export const Labeled = ({children, label, tooltip, sx}: PropsWithChildren<{label?: string; tooltip?: ReactNode; sx?: SxProps<Theme>}>) => {
    return (
        <Box sx={{position: 'relative', width: '100%'}}>
            <Stack width='100%' sx={sx}>
                {label && (
                    <Box display='flex' alignItems='center' justifyContent='space-between' mb={2}>
                        <Typography variant='h2' sx={{wordBreak: 'break-word', textTransform: 'uppercase', hyphens: 'auto'}}>
                            {label || ''}
                        </Typography>
                        {tooltip}
                    </Box>
                )}

                {children}
            </Stack>
        </Box>
    );
};
