/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery, useMutation} from '@tanstack/react-query';
import type {UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {
    User,
    UpdateUserAvatarInput,
    Number,
    PaginatedUser,
    UserControllerGetListParams,
    Object,
    PaginatedPublicStoryDto,
    UserControllerFindAllUserStoriesParams
} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const userControllerUpdateAvatar = (updateUserAvatarInput: UpdateUserAvatarInput) => {
    const formData = new FormData();
    if (updateUserAvatarInput.fileId !== undefined) {
        formData.append('fileId', updateUserAvatarInput.fileId.toString());
    }
    if (updateUserAvatarInput.uploadedFile !== undefined) {
        formData.append('uploadedFile', updateUserAvatarInput.uploadedFile);
    }

    return axiosInstance<User>({url: `/public/user/avatar`, method: 'post', headers: {'Content-Type': 'multipart/form-data'}, data: formData});
};

export type UserControllerUpdateAvatarMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerUpdateAvatar>>>;
export type UserControllerUpdateAvatarMutationBody = UpdateUserAvatarInput;
export type UserControllerUpdateAvatarMutationError = unknown;

export const useUserControllerUpdateAvatar = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdateAvatar>>, TError, {data: UpdateUserAvatarInput}, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerUpdateAvatar>>, {data: UpdateUserAvatarInput}> = (props) => {
        const {data} = props ?? {};

        return userControllerUpdateAvatar(data);
    };

    return useMutation<Awaited<ReturnType<typeof userControllerUpdateAvatar>>, TError, {data: UpdateUserAvatarInput}, TContext>(
        mutationFn,
        mutationOptions
    );
};
export const userControllerSignUserAgreement = () => {
    return axiosInstance<User>({url: `/public/user/sign-user-agreement`, method: 'post'});
};

export type UserControllerSignUserAgreementMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerSignUserAgreement>>>;

export type UserControllerSignUserAgreementMutationError = unknown;

export const useUserControllerSignUserAgreement = <TError = unknown, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof userControllerSignUserAgreement>>, TError, TVariables, TContext>;
}) => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerSignUserAgreement>>, TVariables> = () => {
        return userControllerSignUserAgreement();
    };

    return useMutation<Awaited<ReturnType<typeof userControllerSignUserAgreement>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
};
export const userControllerGetPublishedStoriesLikesCount = (signal?: AbortSignal) => {
    return axiosInstance<Number>({url: `/public/user/statistics/published-stories-likes-count`, method: 'get', signal});
};

export const getUserControllerGetPublishedStoriesLikesCountQueryKey = () => [`/public/user/statistics/published-stories-likes-count`];

export type UserControllerGetPublishedStoriesLikesCountQueryResult = NonNullable<
    Awaited<ReturnType<typeof userControllerGetPublishedStoriesLikesCount>>
>;
export type UserControllerGetPublishedStoriesLikesCountQueryError = unknown;

export const useUserControllerGetPublishedStoriesLikesCount = <
    TData = Awaited<ReturnType<typeof userControllerGetPublishedStoriesLikesCount>>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof userControllerGetPublishedStoriesLikesCount>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUserControllerGetPublishedStoriesLikesCountQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetPublishedStoriesLikesCount>>> = ({signal}) =>
        userControllerGetPublishedStoriesLikesCount(signal);

    const query = useQuery<Awaited<ReturnType<typeof userControllerGetPublishedStoriesLikesCount>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const userControllerGetList = (params?: UserControllerGetListParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedUser>({url: `/public/user/list`, method: 'get', params, signal});
};

export const getUserControllerGetListQueryKey = (params?: UserControllerGetListParams) => [`/public/user/list`, ...(params ? [params] : [])];

export type UserControllerGetListQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerGetList>>>;
export type UserControllerGetListQueryError = unknown;

export const useUserControllerGetList = <TData = Awaited<ReturnType<typeof userControllerGetList>>, TError = unknown>(
    params?: UserControllerGetListParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof userControllerGetList>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUserControllerGetListQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetList>>> = ({signal}) => userControllerGetList(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof userControllerGetList>>, TError, TData>({queryKey, queryFn, ...queryOptions}) as UseQueryResult<
        TData,
        TError
    > & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const userControllerGetUserStoriesCountByStatus = (signal?: AbortSignal) => {
    return axiosInstance<Object[]>({url: `/public/user/statistics/stories-count-by-status`, method: 'get', signal});
};

export const getUserControllerGetUserStoriesCountByStatusQueryKey = () => [`/public/user/statistics/stories-count-by-status`];

export type UserControllerGetUserStoriesCountByStatusQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerGetUserStoriesCountByStatus>>>;
export type UserControllerGetUserStoriesCountByStatusQueryError = unknown;

export const useUserControllerGetUserStoriesCountByStatus = <
    TData = Awaited<ReturnType<typeof userControllerGetUserStoriesCountByStatus>>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof userControllerGetUserStoriesCountByStatus>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUserControllerGetUserStoriesCountByStatusQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetUserStoriesCountByStatus>>> = ({signal}) =>
        userControllerGetUserStoriesCountByStatus(signal);

    const query = useQuery<Awaited<ReturnType<typeof userControllerGetUserStoriesCountByStatus>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const userControllerFindAllUserStories = (params?: UserControllerFindAllUserStoriesParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedPublicStoryDto>({url: `/public/user/stories`, method: 'get', params, signal});
};

export const getUserControllerFindAllUserStoriesQueryKey = (params?: UserControllerFindAllUserStoriesParams) => [
    `/public/user/stories`,
    ...(params ? [params] : [])
];

export type UserControllerFindAllUserStoriesQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerFindAllUserStories>>>;
export type UserControllerFindAllUserStoriesQueryError = unknown;

export const useUserControllerFindAllUserStories = <TData = Awaited<ReturnType<typeof userControllerFindAllUserStories>>, TError = unknown>(
    params?: UserControllerFindAllUserStoriesParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof userControllerFindAllUserStories>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUserControllerFindAllUserStoriesQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerFindAllUserStories>>> = ({signal}) =>
        userControllerFindAllUserStories(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof userControllerFindAllUserStories>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
