import {ThemeOptions} from '@mui/material';

export const whiteTheme: ThemeOptions = {
    palette: {
        primary: {
            main: '#e04e39',
            dark: '#2c2a29',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#c39367',
            dark: '#623b2a',
            contrastText: '#ffffff'
        },
        background: {
            default: '#FFFFFF',
            paper: '#FFFFFF',
            card: '#FCFCFC',
            secondary: '#F4F4F4',
            stripe: '#f3f3f3',
            secondaryConst: '#F4F4F4'
        },
        neutral: {
            main: '#767575',
            contrastText: '#fff'
        },
        text: {
            primary: '#2c2a29',
            secondary: '#D3D2D2',
            primaryConst: '#2c2a29'
        },
        error: {
            main: '#dc3545'
        },
        mode: 'light'
    }
};
