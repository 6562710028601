import {IconButton, Tooltip} from '@mui/material';
import {StarFilledIcon, StarIcon} from 'assets/icons';
import React from 'react';
import {useStoryFavoriteControllerCreate, useStoryFavoriteControllerRemoveOne} from 'api/story-favorite';
import {useQueryClient} from '@tanstack/react-query';
import {getStoryControllerFindAllQueryKey, getStoryControllerFindOneQueryKey} from 'api/story';

export const FavoriteIcon = ({id, isFavorite}: {id: number; isFavorite: boolean}) => {
    const queryClient = useQueryClient();
    const addToFavoriteMutation = useStoryFavoriteControllerCreate();

    const removeFromFavoriteMutation = useStoryFavoriteControllerRemoveOne();
    const addToFavorite = () => {
        addToFavoriteMutation.mutateAsync({data: {storyId: id}}).then(() => {
            queryClient.refetchQueries(getStoryControllerFindAllQueryKey());
            queryClient.refetchQueries(getStoryControllerFindOneQueryKey(id.toString()));
        });
    };
    const removeFromFavorite = () => {
        removeFromFavoriteMutation.mutateAsync({id: id.toString() || ''}).then(() => {
            queryClient.refetchQueries(getStoryControllerFindAllQueryKey());
            queryClient.refetchQueries(getStoryControllerFindOneQueryKey(id.toString()));
        });
    };
    return (
        <Tooltip title='Добавить в избранное'>
            <IconButton
                onClick={(e) => {
                    e.stopPropagation();
                    isFavorite ? removeFromFavorite() : addToFavorite();
                }}>
                {isFavorite ? <StarFilledIcon /> : <StarIcon />}
            </IconButton>
        </Tooltip>
    );
};
