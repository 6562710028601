import React, {createContext, ReactNode, useContext} from 'react';
import {useLocation} from 'react-router';
import {useAuthControllerMe} from 'api/authentication';
import {User} from 'api/mDDAuth.schemas';
import {ROUTES} from 'utils';

export const AuthContext = createContext({} as {me: User | undefined; isLoading: boolean; isError: boolean});

type AuthProviderProps = {
    children: ReactNode;
};
export const AuthProvider = ({children}: AuthProviderProps) => {
    const {pathname} = useLocation();
    const {data, isLoading, isError} = useAuthControllerMe({
        query: {enabled: pathname !== ROUTES.SIGN_IN, retry: false}
    });

    return <AuthContext.Provider value={{me: data, isLoading, isError}}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
