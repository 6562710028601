import {CustomModal} from 'components';
import {getMediaControllerFindAllQueryKey, mediaControllerFindAll} from 'api/media';
import {useInfiniteQuery} from '@tanstack/react-query';
import React, {useMemo} from 'react';
import {ModalProps} from 'hooks';
import {Box, CircularProgress, Grid, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {flatInfinityQuery} from 'utils';

export const SelectMedia = ({open, handleToggle, setMedia}: ModalProps & SelectMediaType) => {
    const {data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage} = useInfiniteQuery(
        getMediaControllerFindAllQueryKey(),
        ({pageParam = 0}) => mediaControllerFindAll({'pagination[skip]': pageParam * 50, 'pagination[take]': 50}),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => (lastPage?.hasNextPage ? +lastPage?.skip / +lastPage?.take + 1 : undefined)
        }
    );
    const media = useMemo(() => flatInfinityQuery(data), [data]);

    return (
        <CustomModal open={open} onClose={handleToggle}>
            <Box
                sx={{
                    p: 2,
                    border: '1px solid #dee2e6',
                    minHeight: 655,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                {media && media.length > 0 ? (
                    <>
                        <Grid container spacing={1} sx={{mb: 2, justifyContent: 'center'}}>
                            {media?.map((item) => (
                                <Grid
                                    item
                                    key={item.id}
                                    sx={{width: 110, height: 110}}
                                    onClick={() => {
                                        setMedia(item);
                                        handleToggle();
                                    }}>
                                    <Box
                                        width='100%'
                                        height='100%'
                                        src={item.file.url}
                                        component='img'
                                        sx={{
                                            objectFit: 'cover',
                                            border: '1px solid rgb(191, 191, 191)',
                                            borderRadius: 2
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        {hasNextPage && (
                            <LoadingButton loading={isFetchingNextPage} variant='outlined' color='neutral' fullWidth onClick={() => fetchNextPage()}>
                                Показать ещё
                            </LoadingButton>
                        )}
                    </>
                ) : (
                    <>{isLoading ? <CircularProgress /> : <Typography>Нет данных</Typography>}</>
                )}
            </Box>
        </CustomModal>
    );
};
type SelectMediaType = {
    setMedia: any;
};
