import React from 'react';
import {AttachIcon, CloseIcon} from 'assets/icons';
import {Button, Typography} from '@mui/material';
import {FieldValues, UseFormSetValue} from 'react-hook-form';

export const FileAttachButton = ({setValue, file, name}: {setValue: UseFormSetValue<FieldValues>; file?: File; name?: string}) => {
    const fileName = file?.name?.split('.').slice(0, -1).join('.');
    const fileExtension = file?.name?.split('.').pop();
    const deleteFile = () => {
        setValue(name || `file`, undefined);
    };
    const ButtonWithFile = () => {
        return (
            <Button sx={{width: 200, height: 52}} variant='text' color='inherit' endIcon={<CloseIcon />} onClick={deleteFile}>
                <Typography noWrap>{fileName}</Typography>
                <Typography sx={{mr: 'auto'}}>{`.${fileExtension}`}</Typography>
            </Button>
        );
    };
    return (
        <>
            {file ? (
                <ButtonWithFile />
            ) : (
                <label>
                    <input type='file' style={{display: 'none'}} onChange={(event) => setValue(name || `file`, event?.target?.files?.[0])} />
                    <Button startIcon={<AttachIcon />} variant='text' color='inherit' component='span'>
                        <Typography>Прикрепить файл</Typography>
                    </Button>
                </label>
            )}
        </>
    );
};
