export enum ERROR_MESSAGE {
    REQUIRED = 'Обязательное поле',
    REQUIRED_EMPTY = ' ',
    EMAIL = 'Неверный формат e-mail',
    INVALID_CREDENTIALS = 'Неверная почта или пароль',
    USER_BLOCKED = 'Пользователь заблокирован. Обратитесь к администратору системы'
}

export enum ROUTES {
    BASE = '/',
    SIGN_IN = '/sign-in',
    CREATE = '/create-story',
    DRAFT = '/draft',
    RATING = '/rating',
    FAVORITE = '/favorites',
    LIKED = '/likes',
    USER_AGREEMENT = '/user-agreement',
    PROFILE = '/profile'
}

export enum CATEGORIES_TAB {
    LATEST = 'latest',
    BEST = 'best',
    VIEWED = 'viewed',
    NOT_VIEWED = 'not-viewed',
    ALL = ''
}

export enum STORIES_STATUS {
    PUBLISHED = 'PUBLISHED',
    MODERATION_FAILED = 'MODERATION_FAILED',
    UNDER_CONSIDERATION = 'UNDER_CONSIDERATION',
    DRAFT = 'DRAFT'
}
