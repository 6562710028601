import React from 'react';
import {Stories} from 'components/shared';
import {Box, Container, Typography} from '@mui/material';

export const Liked = () => {
    return (
        <Container>
            <Box sx={{display: 'flex', mt: 7.5, mb: 6, flexWrap: 'wrap'}}>
                <Typography variant='h1' color='secondary'>
                    Вам&nbsp;
                </Typography>
                <Typography variant='h1' color='primary'>
                    Понравилось
                </Typography>
            </Box>
            <Stories />
        </Container>
    );
};
