import React, {useMemo} from 'react';
import {DoughnutChart} from './DoughnutChart';
import {Box, Grid, Tooltip, Typography} from '@mui/material';
import {BookIcon, CalendarColorIcon, HeartIcon, HelpCircleIcon, StatusColorIcon} from 'assets/icons';
import heart from 'assets/icons/heart.svg';
import {PublicStoryDto, StoryAuthorWithStoryLikesCountDto} from 'api/mDDPublic.schemas';
import {
    getUserControllerFindAllUserStoriesQueryKey,
    userControllerFindAllUserStories,
    useUserControllerGetPublishedStoriesLikesCount,
    useUserControllerGetUserStoriesCountByStatus
} from 'api/user';
import {flatInfinityQuery, formatNumber, ROUTES, STORIES_STATUS} from 'utils';
import {CircularLoading, TableScroll} from 'components';
import {useInfiniteQuery} from '@tanstack/react-query';
import moment from 'moment';
import {HeaderTitle} from '../components/HeaderTitle';
import {useLocation, useNavigate} from 'react-router-dom';

export const Statistics = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {data: likesCount, isLoading: loadingLikesCount} = useUserControllerGetPublishedStoriesLikesCount({});
    const {data: countsStoriesData, isLoading: loadingStoriesCount} = useUserControllerGetUserStoriesCountByStatus({});
    const countsStories = countsStoriesData || [];
    const noPublished = countsStories
        ? countsStories
              ?.filter((el: any) => el.storyStatus !== STORIES_STATUS.PUBLISHED)
              .reduce((partialSum: any, a: any) => partialSum + Number(a.storiesCount), 0)
        : 0;
    const published = countsStories
        ? countsStories
              ?.filter((el: any) => el.storyStatus === STORIES_STATUS.PUBLISHED)
              .reduce((partialSum: any, a: any) => partialSum + Number(a.storiesCount), 0)
        : 0;

    const {data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage} = useInfiniteQuery(
        getUserControllerFindAllUserStoriesQueryKey({}),
        ({pageParam = 0}) => userControllerFindAllUserStories({'pagination[skip]': pageParam * 10, 'pagination[take]': 10}),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => (lastPage?.hasNextPage ? +lastPage?.skip / +lastPage?.take + 1 : undefined)
        }
    );
    const stories = useMemo(() => flatInfinityQuery(data), [data]);
    const loading = isLoading || hasNextPage || isFetchingNextPage;
    const handleClick = (row: PublicStoryDto) => {
        if (row.status === STORIES_STATUS.DRAFT) {
            navigate(ROUTES.DRAFT + '/' + row.id);
        } else navigate(location.pathname + '?storyId=' + row.id);
    };
    return (
        <CircularLoading isLoading={loadingLikesCount || loadingStoriesCount || isLoading}>
            {data && stories.length < 1 ? (
                <Typography textAlign='center'>Нет данных</Typography>
            ) : (
                <Grid container sx={{mt: 3}}>
                    <Grid
                        item
                        xs={12}
                        md={7}
                        sx={{
                            display: 'flex',
                            flexDirection: {xs: 'column', md: 'row'},
                            gap: 5,
                            alignItems: {xs: 'center', md: 'flex-start'}
                        }}>
                        <Box sx={{width: '280px'}}>
                            <DoughnutChart dataChart={[published, noPublished]} />
                        </Box>
                        <Box sx={{order: {xs: -1, md: 0}}}>
                            <Typography variant='h3' sx={{textTransform: 'none', mb: 3, textAlign: {xs: 'center', md: 'start'}}}>
                                {formatNumber(published + noPublished || 0, ['история', 'истории', 'историй'])}
                            </Typography>
                            <Box sx={{display: 'flex', gap: {xs: 2, md: 1}, flexDirection: 'column'}}>
                                <Box sx={{display: 'flex', gap: 2.5, alignItems: 'center'}}>
                                    <Box sx={{height: 30, width: 30, background: '#C39367'}} />
                                    <Typography variant='body2' sx={{fontWeight: 'bold'}}>
                                        Опубликовано
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', gap: 2.5, alignItems: 'center'}}>
                                    <Box sx={{height: 30, width: 30, background: '#623B2A'}} />
                                    <Typography variant='body2' sx={{fontWeight: 'bold'}}>
                                        Не опубликовано
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={5} sx={{display: 'flex', alignItems: {xs: 'flex-start', md: 'center'}, justifyContent: 'center'}}>
                        <Box
                            sx={{width: {xs: '280px', md: '100%'}}}
                            style={{
                                background: `url(${heart})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                height: '280px'
                                // aspectRatio: '1/1'
                            }}>
                            <Box
                                sx={{
                                    color: (theme) => theme.palette.primary.contrastText,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    gap: {xs: 1, md: 2},
                                    height: '100%'
                                }}>
                                <Typography sx={{textTransform: 'uppercase'}} variant='h3' color='primary.contrastText'>
                                    Всего
                                </Typography>
                                <Typography sx={{textTransform: 'uppercase'}} variant='h3' color='primary.contrastText'>
                                    {formatNumber(Number(likesCount), ['лайк', 'лайка', 'лайков'])}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid xs={12} sx={{mt: {xs: 5, md: 10}, maxWidth: '100%', overflow: 'auto'}}>
                        <TableScroll columns={COLUMNS(handleClick)} loading={loading} data={stories} load={fetchNextPage} minWidth={800} />
                    </Grid>
                </Grid>
            )}
        </CircularLoading>
    );
};

const COLUMNS = (handleClick: any) => [
    {
        label: <HeaderTitle title='Название' icon={<BookIcon />} />,
        dataKey: 'name',
        value: (data: PublicStoryDto) => (
            <Typography sx={{cursor: 'pointer'}} onClick={() => handleClick(data)}>
                {data?.title || '-'}
            </Typography>
        )
    },
    {
        label: <HeaderTitle title='Статус' icon={<StatusColorIcon />} />,
        dataKey: 'status',
        value: (data: PublicStoryDto) => <Box color={getStatus(data)?.color}>{getStatus(data)?.title}</Box>
    },
    {
        label: <HeaderTitle title='Дата написания' icon={<CalendarColorIcon />} />,
        dataKey: 'date',
        value: (data: PublicStoryDto) => moment(data?.createdAt).format('DD.MM.YYYY')
    },

    {
        label: <HeaderTitle title='Лайки' icon={<HeartIcon />} />,
        dataKey: 'likesCount',
        value: (data: StoryAuthorWithStoryLikesCountDto) => data?.likesCount || '-'
    }
];
export const getStatus = (data: PublicStoryDto) => {
    const STATUS_TYPE = {
        PUBLISHED: {
            title: 'Опубликована',
            color: 'success.main'
        },
        MODERATION_FAILED: {
            title: (
                <Typography sx={{display: 'flex', alignItems: 'center', gap: 0.5, fontSize: 'inherit'}} color='error.main'>
                    Не прошла модерацию
                    {data?.rejectionReason?.text && (
                        <Tooltip title={data?.rejectionReason?.text} enterTouchDelay={0}>
                            <HelpCircleIcon style={{minWidth: '40px'}} />
                        </Tooltip>
                    )}
                </Typography>
            ),
            color: 'error.main'
        },
        UNDER_CONSIDERATION: {
            title: 'На модерации',
            color: 'warning.main'
        },
        DRAFT: {
            title: 'Черновик',
            color: '#5277AE'
        }
    };
    return STATUS_TYPE[data?.status as keyof typeof STATUS_TYPE];
};
