import {TypographyOptions} from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
    fontFamily: 'PT Sans, sans-serif',
    h1: {
        fontFamily: 'Circe',
        fontWeight: 800,
        fontSize: '48px',
        lineHeight: '60px',
        textTransform: 'uppercase',
        wordBreak: 'break-word'
    },
    h2: {
        fontFamily: 'Circe',
        fontWeight: 'bold',
        fontSize: '26px',
        lineHeight: '30px',
        textTransform: 'uppercase'
    },
    h3: {
        fontFamily: 'Circe',
        fontWeight: 'bold',
        fontSize: '22px',
        lineHeight: '30px',
        textTransform: 'uppercase'
    },
    body2: {
        fontSize: '18px',
        lineHeight: '19px'
    },
    body1: {
        lineHeight: 1.25,
        fontWeight: 500
    },
    button: {
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: '18px'
    }
};
