import React from 'react';
import {Grid, Stack, Typography} from '@mui/material';
import {
    useStatisticsControllerGetOnlineUsersCount,
    useStatisticsControllerGetPublishedStoriesCount,
    useStatisticsControllerGetPublishedStoriesCountByPeriod
} from 'api/statistics';
import moment from 'moment';
import {CircularLoading} from 'components';
import {formatNumber} from 'utils';

export const Statistics = () => {
    const {
        data: publishedStoriesToday,
        isLoading: isPublishedStoriesTodayLoading,
        isError: isPublishedStoriesTodayError
    } = useStatisticsControllerGetPublishedStoriesCountByPeriod({
        'filter[dateFrom]': moment().startOf('day').toISOString(),
        'filter[dateTo]': moment().endOf('day').toISOString()
    });
    const {
        data: publishedStories,
        isLoading: isPublishedStoriesLoading,
        isError: isPublishedStoriesError
    } = useStatisticsControllerGetPublishedStoriesCount();
    const {data: onlineUsers, isLoading: isOnlineUsersLoading, isError: isOnlineUsersError} = useStatisticsControllerGetOnlineUsersCount();

    return (
        <Grid
            container
            rowSpacing={2}
            sx={{
                mt: 5,
                mb: 2,
                bgcolor: '#F4F4F4',
                borderRadius: '10px',
                paddingX: {xs: 3, md: 10},
                paddingY: {xs: 3, md: 5},
                justifyContent: 'space-between'
            }}>
            <Grid item xs={12} md='auto'>
                <StatisticsItem
                    number={onlineUsers}
                    label={['Пользователь на сайте', 'Пользователя на сайте', 'Пользователей на сайте']}
                    isLoading={isOnlineUsersLoading || isOnlineUsersError}
                />
            </Grid>
            <Grid item xs={12} md='auto'>
                <StatisticsItem
                    number={publishedStoriesToday}
                    label={['История сегодня', 'Истории сегодня', 'Историй сегодня']}
                    isLoading={isPublishedStoriesTodayLoading || isPublishedStoriesTodayError}
                />
            </Grid>
            <Grid item xs={12} md='auto'>
                <StatisticsItem
                    number={publishedStories}
                    label={['История всего', 'Истории всего', 'Историй всего']}
                    isLoading={isPublishedStoriesLoading || isPublishedStoriesError}
                />
            </Grid>
        </Grid>
    );
};

// TODO: докопаться до бэка с тайпингом number
const StatisticsItem = ({number, label, isLoading}: {number?: any; label: string[]; isLoading: boolean}) => {
    return (
        <CircularLoading isLoading={isLoading}>
            <Stack direction='row' spacing={2} alignItems='center'>
                <Typography color='error' fontFamily='Circe' fontSize={40} fontWeight={600}>
                    {number}
                </Typography>
                <Typography fontFamily='Circe' fontSize={18} fontWeight={600} color='text.primaryConst'>
                    {formatNumber(number, label, true)}
                </Typography>
            </Stack>
        </CircularLoading>
    );
};
