import {User} from 'api/mDDPublic.schemas';
import {InfiniteData} from '@tanstack/react-query';

export const trimText = (text = '', size: number) => (text.length > size ? text.slice(0, size - 3) + '...' : text);

export const cleanData = (data: {[s: string]: unknown} | ArrayLike<unknown>) =>
    Object.entries(data).reduce((a, [k, v]) => (v == null || v === '' ? a : ((a[k] = v), a)), {} as {[k: string]: any});

export const getUserFullName = (user: User | undefined): string => {
    const arr = [user?.lastName, user?.firstName, user?.middleName];
    return arr.filter((i) => i).join(' ');
};

export const getUserName = (user: User | undefined): string => {
    const arr = [user?.firstName, user?.lastName];
    return arr.filter((i) => i).join(' ');
};
export const getUserNameInitials = (name: string | undefined) => {
    return name
        ? name?.split(' ')?.[1]
            ? `${name.split(' ')?.[0]?.[0]}${name.split(' ')?.[1]?.[0]}`
            : `${name.split(' ')?.[0]?.[0]}`
        : '';
};

export const flatInfinityQuery = (data: InfiniteData<any> | undefined) => {
    return (
        data?.pages
            ?.filter(Boolean)
            .map((i) => i?.data)
            .flat() || []
    );
};
export const formatNumber = (number: number, titles: string[], onlyTitle?: boolean) => {
    //titles example ['день', 'дня', 'дней']
    const cases = [2, 0, 1, 1, 1, 2];
    if (!number) number = 0;
    return (
        (onlyTitle ? '' : number + ' ') +
        titles[
            Math.abs(number) % 100 > 4 && Math.abs(number) % 100 < 20
                ? 2
                : cases[Math.abs(number) % 10 < 5 ? Math.abs(number) % 10 : 5]
        ]
    );
};

export * from './constants';
export * from './auth';
