import React from 'react';
import {HeaderSearch} from 'components/shared/HeaderSearch';
import {RatingTabs} from './RatingTabs';

export const Rating = () => {
    return (
        <>
            <HeaderSearch rating />
            <RatingTabs />
        </>
    );
};
