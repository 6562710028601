import {ArrowForwardIosSharp as ArrowForwardIosSharpIcon} from '@mui/icons-material';
import {Accordion, AccordionDetails, AccordionSummary, Container, Stack, styled, Typography} from '@mui/material';
import React, {ReactNode, useState} from 'react';
import {OldAgreement} from './OldAgreement';
import {NewAgreement} from './NewAgreement';

const StyledAccordion = styled(Accordion)(({theme}) => ({
    backgroundColor: theme.palette.background.card,
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}));

const StyledAccordionSummary = styled(AccordionSummary)(({theme}) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const StyledAccordionDetails = styled(AccordionDetails)(({theme}) => ({
    padding: theme.spacing(2)
}));

export const UserAgreement = () => {
    return (
        <Container>
            <Stack direction='row' flexWrap='wrap' mt={7.5} mb={5}>
                <Typography variant='h1' color='secondary.dark'>
                    Пользовательское&nbsp;
                </Typography>
                <Typography variant='h1' color='secondary'>
                    соглашение
                </Typography>
            </Stack>
            <AccordionItem summary='С 07 июля 2023 года' defaultOpen details={<NewAgreement />} />
            <AccordionItem summary='С 28 июля 2022 по 07 июля 2023' details={<OldAgreement />} />
        </Container>
    );
};

const AccordionItem = ({summary, details, defaultOpen}: {summary: string; details: ReactNode; defaultOpen?: boolean}) => {
    const [expanded, setExpanded] = useState(defaultOpen);
    return (
        <StyledAccordion expanded={expanded} onChange={() => setExpanded((prev) => !prev)} disableGutters elevation={0} square>
            <StyledAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}} />}>
                <Typography>{summary}</Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>{details}</StyledAccordionDetails>
        </StyledAccordion>
    );
};
